export const isAuthenticated = ( auth:any ) => {
    return auth.token && auth.token.access_token && auth.user && auth.user.id;
}

export const domainRoot = () => {
    const theURL:string = document.location.href;

    if ( theURL.includes('localhost') ) {
        return 'localhost';
    }
    else if ( theURL.includes('theintentionaliep.com') ) {
        return '.theintentionaliep.com';
    }
}

export const nextweek = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
}

export const wait = (timeout:any) => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
};
