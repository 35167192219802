import React, { useState } from 'react';
import logo from '../../shared/img/logo.png';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link, Navigate } from 'react-router-dom';

import { setAlert } from '../../redux/alert/alertActions';
import Alert from '../Layout/Alert';

import { connect } from 'react-redux';
import { login } from '../../redux/auth/authAction';


const Login = ( props: any ) => {
    const [formData, setFormData] = useState( { userOrEmail:'', password:''});
    const [showPassword, toggleShowPassword] = useState(false);

    const { userOrEmail, password } = formData;
    const { setAlert, login, auth } = props;

    const onChange = (e:any) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e:any) => {
        e.preventDefault();
        await login({ userOrEmail, password });
    };

    const hasToken = auth && auth.access_token;

    return (
        // hasToken ? <Navigate to="/dashboard" /> :
        <div className="cmt-login">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="The Intentional IEP Logo" className="logo"/>
                            </div>
                            <Card>
                                <CardHeader>Sign In</CardHeader>
                                <CardBody>
                                    <Alert alertKey="signin" />
                                    <Alert alertKey="inactiveUser" />
                                    <form className="auth-form" onSubmit={e => onSubmit(e)}>
                                        <div className={`form-group`}>
                                            <label>Email Address</label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-envelope"></i></div>
                                                <input
                                                    name="userOrEmail"
                                                    value={userOrEmail}
                                                    type="text"
                                                    onChange={e => onChange(e)}
                                                    className="form-control input-ico-left"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>
                                                <div style={{float:'right',fontStyle:'italic'}}>
                                                    <Link to={'/pwreset'}>Forget Your Password?</Link>
                                                </div>
                                                Password
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-key"></i></div>
                                                <input
                                                    name="password"
                                                    value={password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={e => onChange(e)}
                                                    minLength={6}
                                                    className="form-control input-ico-left input-ico-right"
                                                    required
                                                />
                                                <div className="ico-right"
                                                     onClick={() => toggleShowPassword(!showPassword)}>
                                                    <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="flex-row-between">
                                            <input type={`submit`} value={` Sign In `} className={`btn btn-primary`} />
                                            <a href="https://theintentionaliep.thrivecart.com/intentional-iep-subscription/" target="_blank" className="btn btn-success">Need an Account? Sign Up Here</a>
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, login })(Login);



