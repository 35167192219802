import { combineReducers } from 'redux';
import alert from './alert/alertReducer';
import auth from './auth/authReducer';
import modal from './modal/modalReducer';
import cart from './cart/cartReducer';
import builder from './builder/builderReducer';
import browser from './browser/browserReducer';
import search from './search/searchReducer';
import data from './data/dataReducer'
import spinner from './spinner/spinnerReducer';

export default combineReducers({
    alert, auth, modal, cart, builder, browser, search, data, spinner
});
