import {ADD_TO_CART, REMOVE_FROM_CART} from '../types';

export const addToCart = ( goal ) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: goal
  });
};


export const removeFromCart = ( goal ) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: goal
  });
};
