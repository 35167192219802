export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const AUTH_ERROR = 'AUTH_ERROR';


export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const ADD_STRENGTH = 'ADD_STRENGTH';
export const REMOVE_STRENGTH = 'REMOVE_STRENGTH';
export const ADD_NEED = 'ADD_NEED';
export const REMOVE_NEED = 'REMOVE_NEED';
export const SET_NEED_PRIORITY = 'SET_NEED_PRIORITY';
export const GET_ACCOMODS = 'GET_ACCOMODS';

export const LOAD_STRENGTH_CATS = 'LOAD_STRENGTH_CATS';
export const LOAD_NEEDS_CATS = 'LOAD_NEEDS_CATS';
export const LOAD_RAW_NEEDS = 'LOAD_RAW_NEEDS';

export const SEARCH_GOALS = 'SEARCH_GOALS';
export const SET_GRADES = 'SET_GRADES';

export const GET_TRAIT_DESCRIPTION = 'GET_TRAIT_DESCRIPTION';

export const BROWSE_ACCOMODS_CATEGORY = 'BROWSE_ACCOMODS_CATEGORY';
export const BROWSE_CLEAR_ACCOMODS_CATEGORY = 'BROWSE_CLEAR_ACCOMODS_CATEGORY';

export const SEARCH_ACCOMODS = 'SEARCH_ACCOMODS';
export const SEARCH_LOADING = 'SEARCH_LOADING';

export const GENERATE_PDF_URL = 'GENERATE_PDF_URL';

export const SET_DISTRICT = 'SET_DISTRICT';

export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_BROWSER = 'CLEAR_BROWSER';
export const CLEAR_BUILDER = 'CLEAR_BUILDER';
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_MODAL = 'CLEAR_MODAL';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';


// SPINNER
export const SPINNER_ON = 'SPINNER_ON';
export const SPINNER_OFF = 'SPINNER_OFF';
export const SPINNER_CLEAR = 'SPINNER_CLEAR';

// AUTHENTICATION
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOAD_USER = 'LOAD_USER';
export const USER_LOADED = 'USER_LOADED';
export const SET_FACEID_LOGIN = 'SET_FACEID_LOGIN';
export const SET_USERSETTINGS = 'SET_USERSETTINGS';
export const SET_TOKENDATA = 'SET_TOKENDATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
