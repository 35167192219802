import {
    SPINNER_ON, SPINNER_OFF
} from '../types';

const initialState = {
    active: false,
    ctr: 0,
    label: '',
    icon: 'loader'
}

const SpinnerReducer = (state = initialState, action:any) => {
    const { type, payload } = action;
    switch (type) {
        case SPINNER_ON:
            const ctrUp = state.ctr + 1;
            return {
                ctr: ctrUp,
                active: ctrUp > 0,
                label: payload.label,
                icon: payload.icon
            }
        case SPINNER_OFF:
            const ctrDn = state.ctr - 1 <= 0 ? 0 : state.ctr - 1;
            return {
                ...state,
                ctr: ctrDn,
                active: ctrDn > 0,
            }
        default:
            return state;
    }
}

export default SpinnerReducer;
