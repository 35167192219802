import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { openModal } from '../../redux/modal/modalActions';
import { addToCart, removeFromCart } from '../../redux/cart/cartActions';
import PageTitle from '../../components/PageTitle';
import ExportShare from './ExportShare';

const AccoCart = (props: any) => {

    const { cart, addToCart, removeFromCart, openModal } = props;

    const openTheModal = ( title:string ) => {
        openModal( title );
        return false;
    };

    const isInCart = ( amId:string ) => {
        let inCart = false;
        cart.items.map((item:any) => {
            if (item.id === amId) inCart = true;
        });
        return inCart;
    };

    return (
        <>
            <PageTitle title='IEP Plan' builder={true} browse={true} search={true} />
            <Container>
                <Row>
                    <Col sm={{ size: 10, offset: 1 }}>
                        <ExportShare />
                    </Col>
                </Row>
            </Container>
        </>
    );
};



const mapStateToProps = (state: any) => ({
    cart: state.cart
});

export default connect( mapStateToProps, { openModal, addToCart, removeFromCart })(AccoCart);
