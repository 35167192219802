import {SEARCH_ACCOMODS, SEARCH_LOADING} from '../types';
import {apiURL} from "../../services/helpers";

import axios from "axios";

export const searchAccomods = ( searchQuery, grades ) => async dispatch => {

  try {
    dispatch({type: SEARCH_LOADING, payload: true});

    const inGrades = [];
    grades.map((g) => {
      if (g.selected) {
        inGrades.push(`"${g.title}"`);
      }
    });
    
    if (inGrades.length === 0) {
      const q = '{"$or":[{"Payload.Title":{"$regex":"' + searchQuery + '","$options":"i"}},{"Payload.Description":{"$regex":"' + searchQuery + '","$options":"i"}}]}';
      const res = await axios.get(apiURL() + `/api/v2/objects/data/Goals?size=250&i=0&q=${encodeURI(q)}&t=&sort=asc`);
      
      dispatch({
        type: SEARCH_ACCOMODS,
        payload: res.data.r
      });
    }
    else {
      const gradesClause = '{"Payload.Grades.Grade":{$in:[' + inGrades + ']}}';
      const q = '{"$and":[{"$or":[{"Payload.Title":{"$regex":"' + searchQuery + '","$options":"i"}},{"Payload.Description":{"$regex":"' + searchQuery + '","$options":"i"}}]},{"Payload.Grades.Grade":{"$in":[' + inGrades.join(',') + ']}}]}';
      const res = await axios.get(apiURL() + `/api/v2/objects/data/Goals?size=250&i=0&q=${encodeURI(q)}&t=&sort=asc`);

      dispatch({
        type: SEARCH_ACCOMODS,
        payload: res.data.r
      });
    }

  } catch (err) {
    console.log('error loading IIEP category');
  }
}