import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';

import { connect } from 'react-redux';
import { getMatchingAccomods } from '../../redux/builder/builderActions';
import GoalSelectTable from '../../components/GoalSelectTable';


const BuilderSelect = (props: any) => {

    const { data, builder, cart } = props;


    return (
        <>
            <PageTitle title='IEP Goals Builder' browse={true} search={true} />
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={4} />
                                <img src={kids2} alt="students" className="" />
                            </div>
                            <div className='acco-builder-body'>
                                {/*{*/}
                                {/*    builder && builder.accomodsLoading ?*/}
                                {/*        <><h2 className='ltblue centered'>Calculating Matching Goals</h2>*/}
                                {/*        <div className="gears"><img src={gears} alt="loading gears" className="" /></div>*/}
                                {/*        </>*/}
                                {/*    :*/}
                                        <><h2 className='ltblue'>Review and Select Goals</h2>
                                            <p>Based on the strengths and needs you’ve selected, the following is a list of IEP goals you may want to consider. You can further filter down to more specific IEP goals using the filter drop down menus under each Domain and/or the grade level filter in the left sidebar.</p>
                                            { builder.needs.length && builder.needs.map( (topNeed:any, i:number) =>
                                                <GoalSelectTable topNeed={topNeed} key={'goaltable'+i} isSpaced={true} />
                                            )}
                                        </>
                                {/*}*/}
                            </div>
                        </div>
                        <br/><br/>
                        <div className='acco-builder-flowbtn'>
                            { (cart.length) ?
                                <Link to='/builder/export' className='btn btn-pill btn-lg btn-primary'>Next Step <i className="far fa-angle-double-right fa-lg"></i></Link>
                                :
                                <></>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder,
    data: state.data,
    cart: state.cart.items
});

export default connect( mapStateToProps, { getMatchingAccomods })(BuilderSelect);
