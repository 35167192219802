import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import kids1 from '../../shared/img/kids1.png';
import store from '../../store';
import { loadStrengths } from '../../redux/data/dataActions';
import { connect } from 'react-redux';
import { addNeed, removeNeed } from '../../redux/builder/builderActions';
import { openModal } from '../../redux/modal/modalActions';
import { isAuthenticated } from '../../utils/helpers';

const Dashboard = (props: any) => {

    const { auth, loadNeeds, loadStrengths } = props;

    const fname:any = auth.user && auth.user.name && auth.user.name.first ? auth.user.name.first : null;

    const hour = new Date().getHours();
    let timeOfDay = 'Morning';
    if (hour >= 12 && hour <= 17) timeOfDay = 'Afternoon';
    if (hour >= 18 && hour <= 23) timeOfDay = 'Evening';
    if (hour >= 0 && hour <= 2) timeOfDay = 'Evening';


    return (
        <Container className="dashboard">
            <Row>
                <Col md={12} className="welcome">
                    <h1 className="ltblue hXL">Good {timeOfDay}{ fname ? ', '+fname : ''}!</h1>
                    <p>Welcome back to The Intentional IEP. We’re excited that you’re taking action to provide the best education possible for your students, and in doing that - making positive waves in special education.</p>
                </Col>
            </Row>
            <Row>
                {/*<div className='d-none d-lg-block col-lg-3'>*/}
                {/*    <img src={kids1} alt="students" className="img-fluid"/>*/}
                {/*</div>*/}
                <Col lg={12} md={12}>
                    <Row>
                        <Col md={12}><h2 className="ltblue getstarted">Let's Get Started...</h2></Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className={'acco-functions'}>
                            <h4 className="ltblue"><i className="fad fa-tools fa-fw"></i> IEP Goal Builder</h4>
                            <p>Our IEP Goal Builder will help you align your student’s strengths and needs to our database containing thousands of IEP goals to create a custom-fit plan of IEP goals to propose at the IEP meeting.</p>
                            <p><i className="fas fa-arrow-alt-circle-right fa-fw blue"></i> <Link to="/builder/strengths">Start Building</Link></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div  className={'acco-functions'}>
                            <h4 className="ltblue"><i className="fad fa-bookmark fa-fw"></i> Browse By Goal Domain</h4>
                            <p>Our IEP goal database is organized by domain to help you quickly narrow down those that suit your student’s needs.</p>
                            <p><i className="fas fa-arrow-alt-circle-right fa-fw blue"></i> <Link to="/browser/topics">Dive into Topics</Link></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div  className={'acco-functions'}>
                            <h4 className="ltblue"><i className="fad fa-search fa-fw"></i> Search IEP Goals</h4>
                            <p>Looking for specific goals? Use our search engine to find goals by keyword.</p>
                            <p><i className="fas fa-arrow-alt-circle-right fa-fw blue"></i> <Link to="/search">Begin Your Search</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect( mapStateToProps, { addNeed, removeNeed, openModal, loadStrengths })(Dashboard);

