import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import GradePicker from './GradePicker';


const PageTitle = (props: any) => {

    let { title, subtitle, subWidth, browse, search, builder, showGrades } = props;

    subWidth = subWidth || 'half';
    showGrades = showGrades || false;

    return (
        <Container className="acco-page-title">
            <Row>
                <Col sm={12}>
                    <div className={`acco-page-title-text`}>
                        <div className={`acco-page-title-links`}>
                            { builder && <span className='acco-page-title-link'><i className="fas fa-tools fa-lg fa-fw"></i> <Link to='/builder/strengths'>Builder</Link></span> }
                            { browse && <span className='acco-page-title-link'><i className="fas fa-book-open fa-lg fa-fw"></i> <Link to='/browser/topics'>Browse</Link></span> }
                            { search && <span className='acco-page-title-link'><i className="fas fa-search fa-lg fa-fw"></i> <Link to='/search'>Search</Link></span> }
                        </div>
                        <h1 className={`ltblue`}>{ title }</h1>
                        <div className="acco-page-title-description">
                            <div>
                                { subtitle && <p>{ subtitle }</p>}
                            </div>
                            <div>
                                { showGrades ?
                                    <GradePicker />
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    );
};

export default PageTitle;
