import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import axios from 'axios';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/alert/alertActions';
import { login } from '../../redux/auth/authAction';
import Topbar from '../Layout/topbar/Topbar';
import { Buffer } from 'buffer';
import Alert from "../Layout/Alert";
import { UserData } from "../../models/UserData";

interface ResetPassword {
    token: string;
    uid: string;
    password: string;
    confirmPassword: string;
}

const RegisterComplete = (props: any) => {
    const { auth, setAlert, login } = props;

    let location = useLocation();

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [lengthError, setLengthError] = useState(false);
    const [matchError, setMatchError] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [userFound, setUserFound] = useState(false);

    const [formData, setFormData] = useState<ResetPassword>({ token: '', uid: '', password: '', confirmPassword: '' });
    const [paramsToken, setParamsToken] = useState('');
    const [showPassword, toggleShowPassword] = useState(false);
    const [showConfirmPassword, toggleShowConfirmPassword] = useState(false);

    const [success, setSuccess] = useState(false);

    const { uid, password, confirmPassword } = formData;

    const getUserAccount = async () => {
        let params = location.search.substring(1);
        let token = params.substring("token=".length);
        setParamsToken(token);
        let userData = JSON.parse(Buffer.from(token, 'base64').toString('utf8')) as UserData;

        try {
            const pwResetTokenRes = await axios.post("/iep-api/registration/validate", {
                token
            });
            if (pwResetTokenRes.status === 200 && pwResetTokenRes.data != null) {
                setFormData({ ...formData, token: pwResetTokenRes.data, uid: userData.email });

                if (userData) {
                    setUserFound(true);
                }
            }
        }
        catch (err) {
            console.error(err);
        }

        setLoading(false);
    }

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, password: e.target.value });
    }

    const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, confirmPassword: e.target.value });
    }

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password.length < 8) {
            setLengthError(true);
            return;
        }

        if (password !== confirmPassword) {
            setMatchError(true);
            return;
        }

        setSubmitting(true);

        try {
            const body = new URLSearchParams({ token: formData.token, uid: formData.uid, password: formData.password, confirmPassword: formData.confirmPassword }).toString();
            const res = await axios.post('/api/v2/sys/Account/pwd/reset', body);
            const completeRes = await axios.post('/iep-api/registration/complete', {
                token: paramsToken
            });
            setSubmitting(false);
            setSuccess(true);
        } catch (err) {
            setSubmitting(false);
            setSubmitError(true);
        }

        setSubmitting(false);
    }

    useEffect(() => {
        setLengthError(false);
        setMatchError(false);
    }, [password, confirmPassword]);

    useEffect(() => {
        if (loading) {
            getUserAccount();
        }
    }, [loading]);

    const hasToken = auth && auth.access_token;

    return (hasToken ? <Navigate to="/dashboard" /> :
            <>
                <Topbar />
                {loading &&
                    <div className="cmt-login">
                        <div className="cmt-login-inner">
                            <Container className={'acco-register'}>
                                <Row>
                                    <Col md={{ size: 8, offset: 2 }}>
                                        <h1 className={`ltblue`}>Loading...</h1>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                }
                {!loading && userFound &&
                    <div className="cmt-login">
                        <div className="cmt-login-inner">
                            <Container className={'acco-register'}>
                                <Row>
                                    <Col md={{ size: 8, offset: 2 }}>
                                        <div>
                                            <h1 className={`ltblue`}>{!success ? "Complete Your Registration" : "Registration Complete"}</h1>
                                            {!success && <>
                                                <p>
                                                    Thank you for creating your account through our ThriveCart payment
                                                    system. To complete your account, please set a password using the fields below.
                                                </p>
                                                <p>
                                                    If you have already set your
                                                    password, you can <Link to={"/login"}>login</Link> or <Link to={"/pwreset"}>reset your password</Link>.
                                                </p>
                                            </>
                                            }
                                        </div>
                                        <br />
                                        <Card>
                                            <CardHeader>{!success ? "Create Your Password" : "Password Created Successfully"}</CardHeader>
                                            <CardBody>
                                                {!success && <form className="auth-form" onSubmit={submit}>
                                                    <Alert alertKey="signinError" />
                                                    <div>Your password must be at least 8 characters.</div>
                                                    <br />
                                                    <div className={`form-group`}>
                                                        <label>Password</label>
                                                        <div className="auth-form-group">
                                                            <div className="ico-left"><i className="fas fa-key"></i>
                                                            </div>
                                                            <input
                                                                name="password"
                                                                value={password}
                                                                minLength={8}
                                                                type={showPassword ? 'text' : 'password'}
                                                                onChange={onPasswordChange}
                                                                className="form-control input-ico-left input-ico-right"
                                                                required
                                                            />
                                                            <div className="ico-right"
                                                                 onClick={() => toggleShowPassword(!showPassword)}>
                                                                <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`form-group`}>
                                                        <label>Confirm Password</label>
                                                        <div className="auth-form-group">
                                                            <div className="ico-left"><i className="fas fa-key"></i>
                                                            </div>
                                                            <input
                                                                name="confirmPassword"
                                                                value={confirmPassword}
                                                                minLength={8}
                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                onChange={onConfirmPasswordChange}
                                                                className="form-control input-ico-left input-ico-right"
                                                                required
                                                            />
                                                            <div className="ico-right"
                                                                 onClick={() => toggleShowConfirmPassword(!showConfirmPassword)}>
                                                                <i className={`fas ` + (showConfirmPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {lengthError && <div className='form-group red'>
                                                        <i className="fas fa-exclamation-triangle"></i> Password must be at least 8 characters
                                                    </div>
                                                    }
                                                    {matchError &&
                                                        <div className='form-group red'>
                                                            <i className="fas fa-exclamation-triangle"></i> Passwords do not match
                                                        </div>
                                                    }
                                                    {submitError &&
                                                        <div className='form-group red'>
                                                            <i className="fas fa-exclamation-triangle"></i> There was an error while submitting your password. Please try again later.
                                                        </div>
                                                    }
                                                    <div className='form-group'>
                                                        <div>
                                                            <button className='btn btn-primary' type="submit" disabled={submitting}>Complete Registration</button>
                                                            <Link to={"/login"}
                                                                  style={{ float: 'right', fontStyle: 'italic' }}>Already set your password?</Link>
                                                        </div>
                                                    </div>
                                                </form>
                                                }
                                                {success &&
                                                    <>
                                                        <div className='form-group green'>
                                                            <i className="fas fa-check"></i> Your password has been set! Please return to login and get started.
                                                        </div>
                                                        <Link className='btn btn-primary' to={"/login"}>Login</Link>
                                                    </>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                }
                {!loading && !userFound &&
                    <Container className={'acco-register'}>
                        <Row>
                            <Col md={12}>
                                <PageTitle title='Invalid Key'
                                           subWidth='full'
                                           subtitle={'This password URL is invalid. It is probable that the password has already been set up for this account. If you believe this is an error, please try to reset your password, or contact support at support@theintentionaliep.com.'} />
                                <Link to={"/pwreset"} className="btn btn-primary">Reset Password</Link>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert, login })(RegisterComplete);
