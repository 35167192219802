export const getNeedsBranch = ( keys:string[], searchKey:string, needsMap:any, logKeys:boolean = false) => {
    needsMap.map( (need:any) => {
        if (logKeys) {
            keys.push( need.Id )
        }
        getNeedsBranch( keys, searchKey, need.Needs, need.Id === searchKey || logKeys );
    });
    return keys;
}

export const organizeNeeds = ( needsArray:any ) => {
    const hierarchy:any = [];

    // Create a mapping of objects based on their Id
    const needsMap:any = {};
    needsArray.forEach((need:any) => {
        needsMap[need.Id] = need;
        need.Needs = [];
    });

    // Iterate through the objects and build the hierarchy
    needsArray.forEach((need:any) => {
        const parentId = need.ParentId;
        if (parentId) {
            const parent = needsMap[parentId];
            if (parent) {
                parent.Needs.push(need);
            } else {
                console.error(`Parent with Id ${parentId} not found for object with Id ${need.Id}`);
            }
        } else {
            hierarchy.push(need);
        }
    });

    return hierarchy;
}
