import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { openModal } from '../../redux/modal/modalActions';
import { addToCart, removeFromCart } from '../../redux/cart/cartActions';
import SelectTable from '../../components/SelectTable';
import axios from 'axios';
import { iiepApiUrl, monitorFormAlgorithm } from '../../services/helpers';
import { wait } from '../../utils/helpers';


const ExportShare = (props: any) => {

    const { modal, builder, cart, addToCart, removeFromCart, openModal, user } = props;

    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [showPdfIframe, setShowPdfIframe] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [sending, setSending] = useState(false);

    const [selectedPersonnel, setSelectedPersonnel] = useState([]);
    const [otherEmails, setOtherEmails] = useState('');
    const [message, setMessage] = useState('');


    const openTheModal = (title: string) => {
        openModal(title);
        return false;
    };

    const isInCart = (amId: string) => {
        let inCart = false;
        cart.items.map((item: any) => {
            if (item.id === amId) inCart = true;
        });
        return inCart;
    }

    const toggleSendEmail = () => {
        setSendEmailOpen(!sendEmailOpen);
        return false;
    };

    const toggleEmailSent = () => {
        setEmailSent(!emailSent);
        return false;
    };

    const loadPDF = async () => {
        setPdfLoading(true);
        const linkURL = await generateThePdf();
        setPdfLoading(false);
        console.log('success', linkURL);
        setPdfUrl(linkURL);
        setShowPdfIframe(true);
    }

    const closePDF = () => {
        setShowPdfIframe(false);
    }

    const generateThePdf = async () => {
        const builderOut = {
            needs: builder.needs,
            strengths: builder.strengths
        };
        const cartOut = { ...cart };
        const data = { data: { builder: builderOut, cart: cartOut } };
        const res: any = await axios.post(iiepApiUrl() + '/pdf/generate', data);
        //console.log(res);
        if (res && res.data && res.data.url) {
            return res.data.url;
        }
        return false;
    }

    const monitorForm = async () => {
        setPdfLoading(true);
        const accomods = !builder.needs.length ? cart.items : builder.accomods;
        const data = monitorFormAlgorithm(cart.items, builder.needs, accomods);
        const res = await axios.post(iiepApiUrl() + '/pdf/monitorform', JSON.stringify(data));
        console.log(res);
        if (res && res.data && res.data.url) {
            setPdfUrl(res.data.url);
            setShowPdfIframe(true);
        }
        setPdfLoading(false);
    }

    const setEmailUsers = (e: any) => {
        let values: any = Array.from(e.target.selectedOptions, (option: any) => option.value);
        setSelectedPersonnel(values);
    }

    const sendEmail = async () => {
        setSending(true);
        setEmailError(false);
        let other = otherEmails.split('\n');

        let data = {
            data: {
                sender: {
                    last: user.name.last,
                    first: user.name.first,
                    email: user.emailAddress,
                },
                emails: other,
                message: message,
                builder: builder,
                cart: cart
            }
        }

        const res = await axios.post(iiepApiUrl() + '/pdf/email', JSON.stringify(data));

        if (res.data.success) {
            setEmailSent(true);
        } else {
            setEmailError(true);
        }

        await wait(1500);
        setEmailSent(true);
        setSending(false);
    }

    return (
        <>
            <h2 className='ltblue'>Export and Share IEP</h2>

            {!cart.items.length ?
                <>
                    <p>You have not added any Goals to your plan. Choose one of the options above to
                        find Goals suited to your student. </p>
                    <br/>
                </>
                :
                <>
                    <p>Now that you’ve found some IEP goals, you can download them to share and consider with other members of the IEP Team. You may also browse and search for more IEP goals and add them to your plan before you export.</p>
                    <br/>
                    <SelectTable data={cart.items} showCartActions={true} emptyMessage=""/>
                    <br/><br/>
                    <div className='text-center'>
                        {/*<a className='btn btn-pill btn-primary'><i className="fad fa-file-pdf fa-lg"></i> &nbsp;Download PDF</a> &nbsp;&nbsp;*/}
                        <button className='btn btn-pill btn-primary' onClick={() => openModal('', 'xl')}
                                disabled={pdfLoading}>
                            <i className="fad fa-browser"></i> &nbsp;View Selected Goals
                        </button>
                        &nbsp;&nbsp;
                        <button className='btn btn-pill btn-primary' onClick={() => loadPDF()} disabled={pdfLoading}>
                            <i className="fas fa-file-pdf"></i> &nbsp;Download IEP Goal PDF
                        </button>
                        &nbsp;&nbsp;
                        <button className='btn btn-pill btn-primary' onClick={() => toggleSendEmail()}
                                disabled={pdfLoading}>
                            <i className="fad fa-paper-plane"></i> &nbsp;Email Goals to Team
                        </button>
                        &nbsp;&nbsp;
                    </div>
                </>
            }
            {pdfLoading ?
                <div className='text-center' style={{ zoom: '120%' }}>
                    <i className={"fas fa-cog fa-spin"}></i> Working...
                </div>
                : <></>
            }

            <br/><br/>

            {sendEmailOpen &&
              <div>
                <h3 className="ltblue">Send in Email</h3>
                <br/>
                  {!emailSent ?
                      <>
                          <Row>
                              <Col md={6}>
                                  <b>Email Addresses:</b> (one per line)
                                  <textarea className='emailbox'
                                            onChange={(e: any) => setOtherEmails(e.target.value)}></textarea>
                              </Col>
                              <Col md={6}>
                                  <b>Custom Message:</b>
                                  <textarea className='emailbox'
                                            onChange={(e: any) => setMessage(e.target.value)}></textarea>
                              </Col>
                          </Row>
                          <br/>
                          {emailError &&
                            <div className="text-center">
                              <div className="alert alert-error text-center">
                                <b>There was an error sending your email. Please try again.</b>
                              </div>
                            </div>
                          }
                          <div className='text-center'>
                              <button className='btn btn-pill btn-primary' onClick={() => sendEmail()}
                                      disabled={sending}>
                                  <i className="fad fa-paper-plane"></i> &nbsp;{sending ? 'Sending...' : 'Send Email'}
                              </button>
                              &nbsp;&nbsp;
                          </div>
                      </>
                      :
                      <div className="text-center">
                          <div className="alert alert-success text-center">
                              <p>&nbsp;<br/><b>Your email has been sent successfully!</b></p>
                              <br/>
                              <p><a className='btn btn-pill btn-success' onClick={() => setEmailSent(false)}>
                                  &nbsp;&nbsp; Close &nbsp;&nbsp;
                              </a></p>
                          </div>
                      </div>
                  }
              </div>
            }

            {showPdfIframe &&
              <div className="pdf-iframe" id="pdf-iframe-modal">
                <div className="pdf-iframe-body">
                  <div className="pdf-iframe-content">
                    <iframe src={pdfUrl}></iframe>
                    <div className="pdf-iframe-close" onClick={() => closePDF()}>
                      <i className="fas fa-circle"></i>
                      <i className="far fa-times-circle"></i>
                    </div>
                      {/*<div className="pdf-iframe-save">*/}
                      {/*  <a href={pdfUrl} className='btn btn-success' download>Download PDF</a>*/}
                      {/*</div>*/}
                  </div>
                </div>
              </div>
            }
        </>
    );
};


const mapStateToProps = (state: any) => ({
    modal: state.modal,
    cart: state.cart,
    builder: state.builder,
    user: state.auth.user
});

export default connect(mapStateToProps, { openModal, addToCart, removeFromCart })(ExportShare);
