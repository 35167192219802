import {ADD_NEED, ADD_STRENGTH, GET_ACCOMODS, REMOVE_NEED, REMOVE_STRENGTH, SET_NEED_PRIORITY} from '../types';
import axios from "axios";
import {apiURL} from "../../services/helpers";
import {BuilderNeed} from "./builderModels";

export const addStrength = ( strength:any ) => (dispatch:any) => {
  dispatch({
    type: ADD_STRENGTH,
    payload: strength
  });
}


export const addNeed = ( need: BuilderNeed ) => async (dispatch: any) => {
  console.log('addneed', need);
  dispatch({ type: ADD_NEED, payload: need });
}

export const removeStrength = ( strength:any ) => (dispatch:any) => {
  dispatch({
    type: REMOVE_STRENGTH,
    payload: strength
  });
}

export const removeNeed = ( need: BuilderNeed ) => (dispatch:any) => {
  dispatch({
    type: REMOVE_NEED,
    payload: need
  });
}

export const setNeedPriorities = ( needs:any ) => (dispatch:any) => {
  dispatch({
    type: SET_NEED_PRIORITY,
    payload: needs
  });
}

export const getMatchingAccomods = ( selectedNeeds:any, selectedStrengths:any ) => async ( dispatch:any )=> {

  const needsArray:any = [];
  const strengthsArray:any = [];

  selectedNeeds.forEach((n:any) => {
    needsArray.push(n.Name);
  });
  selectedStrengths.forEach((s:any) => {
    strengthsArray.push(s.Name);
  });

  try {
    const needs = "'Payload.NeedsPriorities': { $elemMatch: { 'Need.Name': { $in: ['"+needsArray.join("','")+"'] }}}";
    //const strengths = "'Payload.StrengthPriorities': { $elemMatch: { 'Strength.Name': { $in: ['"+strengthsArray.join("','")+"'] }}}";
    const q = "{" + needs + "}";

    const res = await axios.get(apiURL()+'/Accomods?action=get&i=0&size=250&q='+encodeURI(q));

    /**********************************************/
    let accoClone = [];

    accoClone = [...res.data.r];

    /// CALCULATE SCORE FOR EACH ACCOMOD
    accoClone.map( (doc, i) => {
      let score = 0;
      if (doc.NeedsPriorities) {
        doc.NeedsPriorities.forEach( (accoNeeds:any) => {
          selectedNeeds.forEach( (userNeeds:any) => {
            if ( accoNeeds.Need.Name === userNeeds.Name ) {
              score += userNeeds.priority * accoNeeds.Priority;
            }
          });
        });
      }
      if (doc.StrengthPriorities) {
        doc.StrengthPriorities.forEach( (accoStrengths:any) => {
          selectedStrengths.forEach( (userStrengths:any) => {
            if (accoStrengths.Strength.Name === userStrengths.Name ) {
              score += accoStrengths.Priority;
            }
          });
        });
      }
      //console.log('scoring '+i+': '+score);
      doc.score = score;
      return doc;
    });

    /// SORT THE ACCOMODS
    accoClone.sort((a, b) => (a.score < b.score) ? 1 : -1);

    /**********************************************/

    dispatch({
      type: GET_ACCOMODS,
      payload: accoClone
    });

  } catch(err) {
    console.log('Error getting matching accomods', err);
  }

}
