import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setGrades } from '../redux/data/dataActions';

const GradePicker = (props: any) => {
    const { grades, setGrades } = props;

    const [pickerOpen, setPickerOpen] = useState( false );

    let selectedGrades = 0;
    grades.map( (g:any) => selectedGrades += g.selected ? 1 : 0 );

    return (
        <div className="grade-picker-wrapper">
            <div className="grade-picker">
                <div className="grade-picker-active-box" onClick={()=> !selectedGrades ? setPickerOpen( !pickerOpen ) : null}>
                    {grades.map((grade: any) => grade.selected ?
                        <div className="grade-picker-active" key={'gr-'+grade.title}>
                            <i className="far fa-times"></i>
                            {grade.title === 'Kindergarden' ? 'Kind' : grade.title.replace(' Grade', '')
                            }
                        </div>
                        :
                        <></>
                    )}
                    { !selectedGrades ?
                        <p className="no-grades">Filter by Grade</p>
                        :
                        <></>
                    }
                </div>
                <div className="grade-picker-arrow" onClick={()=>setPickerOpen( !pickerOpen )}>
                    <i className="fas fa-chevron-down"></i>
                </div>
            </div>
            { pickerOpen ?
                <div className="grade-picker-select">
                    <div className="grade-select-div">
                        <ul>{ grades.map( (grade:any) =>
                            <li key={'g'+grade.title}><label>
                                <input type="checkbox" name={grade.title} value={1} checked={grade.selected}
                                       onChange={(e:any)=>setGrades(e.target.name, grades)} />
                                { grade.title }
                            </label></li>
                        )}
                        </ul>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    grades: state.data.grades,
});

export default connect(mapStateToProps, { setGrades })(GradePicker);


