import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import BrowserNav from './BrowserNav';
import BrowserTopicDetails from './BrowserTopicDetails';
import { clearBrowseCategory, getBrowserCategory } from '../../redux/browser/browserActions';
import kids2 from '../../shared/img/kids2.png';
import { connect } from 'react-redux';
import gears from '../../shared/img/gears.gif';
import GoalSelectTable from '../../components/GoalSelectTable';
import { getNeedsBranch } from '../../redux/data/dataHelper';

const _ = require('lodash');

const BrowserTopics = (props: any) => {

    const { data, browser, getBrowserCategory, clearBrowseCategory } = props;

    const needsLoaded = data && data.needs && data.needs.length;
    const needs = needsLoaded ? data.needs : [];
    const [activeNeedId, setActiveNeedId] = useState('');

    console.log('activeNeedId', activeNeedId);

    const selectFromDropDown = ( needId:string ) => {
        let theNeed:any = {};

        needs.map( (topNeed:any) => {
            topNeed.Needs.map( (need:any) => {
                if (need.Id === needId) {
                    theNeed = _.cloneDeep( need );
                }
            });
        });
        setActiveNeedId( theNeed );
    }

    return (
        <>
        <PageTitle title='Browse Goals' builder={true} browse={false} search={true} showGrades={true}
                   subtitle={'We\'ve organized our database of IEP goals by domain to help you quickly narrow down those that best suit your student\'s needs.'} />
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='acco-browser'>
                        <div className='acco-browser-select'>
                            <select className='form-control' onChange={(e:any)=>selectFromDropDown(e.target.value)}>
                                <option value=''>Please select a category</option>
                                <option disabled key='selector-space' ></option>
                                { needs.map( (cat:any, i:number) =>
                                    <>
                                    <option disabled key={`cat`+i} >{ cat.Name }</option>
                                    { cat.Needs.map( (n:any) => <option value={n.Id} key={n.Id}>{ n.Name }</option> ) }
                                    <option disabled key={`space`+i} ></option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div className='acco-browser-nav'>
                            { needs.map( (cat:any) => <BrowserNav category={cat} topicClick={setActiveNeedId} key={cat.Id} /> ) }
                        </div>
                        <div className='acco-browser-body'>
                            { activeNeedId !== '' ?
                                <GoalSelectTable topNeed={activeNeedId} key={'goaltable'+activeNeedId} />
                                :
                                <div className="alert alert-success text-center">&nbsp;<br/>To begin, select a topic from
                                    the navigation menu on the left.<br/>&nbsp;
                                </div>
                            }
                            {/**/}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    data: state.data,
    browser: state.browser,
});

export default connect(mapStateToProps, { getBrowserCategory, clearBrowseCategory })(BrowserTopics);
