import { CLEAR_DATA, LOAD_NEEDS_CATS, LOAD_STRENGTH_CATS, LOAD_RAW_NEEDS, SEARCH_GOALS, SET_GRADES } from '../types';

const initialState:any = {
    rawNeeds: [],
    needs: [],
    strengths: [],
    goals: {},
    grades: [
        { title: 'Pre-K', selected: false, },
        { title: 'Kindergarden', selected: false, },
        { title: '1st Grade', selected: false, },
        { title: '2nd Grade', selected: false, },
        { title: '3rd Grade', selected: false, },
        { title: '4th Grade', selected: false, },
        { title: '5th Grade', selected: false, },
        { title: '6th Grade', selected: false, },
        { title: '7th Grade', selected: false, },
        { title: '8th Grade', selected: false, },
        { title: '9th Grade', selected: false, },
        { title: '10th Grade', selected: false, },
        { title: '11th Grade', selected: false, },
        { title: '12th Grade', selected: false, },
    ]
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_RAW_NEEDS:
            return {
                ...state,
                rawNeeds: payload
            }
        case LOAD_NEEDS_CATS:
            return {
                ...state,
                needs: payload
            }
        case LOAD_STRENGTH_CATS:
            return {
                ...state,
                strengths: payload
            }
        case CLEAR_DATA:
            return {
                needs: [],
                strengths: []
            }
        case SEARCH_GOALS:
            return {
                ...state,
                goals: payload,
            }
        case SET_GRADES:
            return {
                ...state,
                grades: payload
            }
        default:
            return state;
    }
}
