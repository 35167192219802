import React from 'react';
import { Link, useLocation } from 'react-router-dom';


const MainWrapper = (props: any) => {
  const { children } = props;
  const year = new Date().getFullYear();

  const location = useLocation();

    const pathname = location.pathname;

    return (
      <div className={pathname==='/'?'bg-wave':''}>
        <div className="wrapper">
          {children}
        </div>
          <div className='footer'>
            <b>Copyright © { year } The Intentional IEP.</b>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.theintentionaliep.com/terms-and-conditions-of-use/" target="_blank">Terms of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.theintentionaliep.com/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.theintentionaliep.com/disclosures-disclaimers/" target="_blank">Disclaimers</a>
          </div>
      </div>
    );

}

export default MainWrapper;
