import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { logout } from '../../../redux/auth/authAction';
import logo from '../../../shared/img/logo.png';

import { connect } from 'react-redux';
import { isAuthenticated } from '../../../utils/helpers';

const Topbar = (props: any) => {

    const { logout, auth, cart } = props;
    const [menuOpen, toggleMenu] = useState(false);
    const navigate = useNavigate();

    const name = auth.user && auth.user.name && auth.user.name.first ? auth.user.name.first + ' ' + auth.user.name.last : '';

    const signout = async () => {
        await logout();
    }

    return (
        <header id="page-head">
            <div><Link to={'/'}><img src={logo} /></Link></div>
            { isAuthenticated( auth ) ?
                <div className="action-group">
                    <div className="button flex" onClick={() => toggleMenu(!menuOpen)}>
                        <div>{ name }</div>
                        <div><i className="fad fa-caret-down"></i></div>
                        <Collapse isOpen={menuOpen} className="topbar__menu-wrap">
                            <div className="topbar__menu">
                                <Link className="topbar__link" to={"/my-profile"}>
                                    My Profile
                                </Link>
                                <div className="topbar__menu-divider"/>
                                <a className="topbar__link" href="https://products.theintentionaliep.com/updateinfo/" target="_blank">
                                    My Account
                                </a>
                                <div className="topbar__menu-divider"/>
                                <a className="topbar__link" href="https://training.theintentionaliep.com" target="_blank">
                                    Professional Development
                                </a>
                                <div className="topbar__menu-divider"/>
                                <a className="topbar__link" href="https://training.theintentionaliep.com/page/2qWOe" target="_blank">
                                    Member Resources
                                </a>
                                <div className="topbar__menu-divider"/>
                                <div className="topbar__link" onClick={()=>signout()}>
                                    Sign Out
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="button flex" onClick={()=>navigate('/plan')}>
                        <div className="cartbadge">{ cart && cart.items ? cart.items.length : 0 }</div>
                        <div>My Plan</div>
                    </div>
                </div>
            :
                <div></div>
            }
        </header>
    );

}


const mapStateToProps = (state: any) => ({
    cart: state.cart,
    auth: state.auth
});

export default connect(mapStateToProps, { logout })(Topbar);
