import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from '../containers/Login/Login';
import MainWrapper from '../components/MainWrapper';
import { removeAlert } from '../redux/alert/alertActions';
import { connect } from 'react-redux';
import RegisterIndividual from '../containers/Register/RegisterIndividual';
import RegisterComplete from '../containers/Register/RegisterComplete';
import Dashboard from '../containers/Dashboard/Dashboard';
import BuilderStrengths from '../containers/Builder/BuilderStrengths';
import BuilderNeeds from '../containers/Builder/BuilderNeeds';
import BuilderPrioritize from '../containers/Builder/BuilderPrioritize';
import BuilderSelect from '../containers/Builder/BuilderSelect';
import BuilderExport from '../containers/Builder/BuilderExport';
import BrowserTopics from '../containers/Browser/BrowserTopics';
import Search from '../containers/Search/Search';
import AccoCart from '../containers/Cart/AccoCart';
import MyAccount from '../containers/MyProfile/MyProfile';
import Layout from '../containers/Layout';
import AccoModal from '../components/AccoModal';
import Cookies from 'universal-cookie';

import GoToLogin from './GoToLogin';
import { keepAlive, loadUser, logout, setLoginData } from '../redux/auth/authAction';
import useScrollToTop from '../hooks/useScrollToTop';
import { isAuthenticated } from '../utils/helpers';
import { loadAllNeeds, loadStrengths } from '../redux/data/dataActions';
import DataImport from '../data/DataImport';
import Spinner from '../containers/Layout/spinner';
import { setSpinner } from '../redux/spinner/spinnerActions';
import PwResetForm from '../containers/PwReset/PwResetForm';
import PwReset from '../containers/PwReset/PwReset';

const Router = (props: any) => {

    const { auth, profile, modal,  logout, loadUser, loadStrengths, keepAlive, setLoginData, setSpinner, loadAllNeeds } = props;

    const [checkedLocalData, setCheckedLocalData] = useState( false );
    const [pageState, setPageState] = useState( -1 );

    const location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname;
    const cookies = new Cookies();

    const isSignedIn = isAuthenticated( auth );
    const cookieData:any = cookies.get( 'intentIepUserData' );

    const dataFetchedRef = useRef(false);

    useScrollToTop();

    // SET STORED USER DATA IF STORED TOKEN IS STILL GOOD
    // THIS WILL KICK OFF THE NEXT USEEFFECT
    useEffect( () => {
        (async () => {
            if (isSignedIn) {
                setPageState(1);
                setCheckedLocalData( true );
            }
            else if (!isSignedIn && !cookieData) {
                setPageState(0);
                setCheckedLocalData( true );
            }
            else if (!isSignedIn && !!cookieData && !checkedLocalData) {
                const now = new Date();
                const expires = new Date(cookieData['.expires']);
                if ( now < expires ) {
                    await setLoginData( cookieData );
                    setPageState(1);
                } else {
                    await logout();
                    setPageState(0);
                }
                setCheckedLocalData( true );
            }
        })();
    },[isSignedIn]);

    // LOAD USER DATA ANYTIME THE TOKEN CHANGES
    useEffect( () => {
        (async () => {
            if (!!auth.token.access_token) {
                if(await loadUser(auth.token.access_token) == 0)
                {
                    await logout();
                    navigate('/');
                }
            }
        })();
    },[auth.token.access_token]);

    // KEEP TOKEN ALIVE AS LONG AS USER IS AUTHENTICATED
    useEffect(() => {
        const ka = setInterval(async () => {  //assign interval to a variable to clear it.
            if (isSignedIn) { await keepAlive();  }
        }, (1000*60)); // run every minute
        return () => clearInterval(ka); //This is important
    }, [])

    // REDIRECT USER IF SIGNED IN
    useEffect(() => {
        if (pathname === '/' && isSignedIn) { navigate('/dashboard'); }
    },[pathname, isSignedIn])

    useEffect(() => {
        if (isAuthenticated( auth )) {
            (async () => {
                if (dataFetchedRef.current) return;     /// ENSURES ONLY RUN ONCE
                dataFetchedRef.current = true;
                setSpinner( true, 'Loading Needs Hierarchy');
                await loadStrengths();
                await loadAllNeeds();
                setSpinner(false);
            })();
        }
    }, [auth]);


    return (
        <MainWrapper>
            <main>
                { pageState === 1 ?
                    <>
                        <div className={'mainBody' + (modal.open ? ' acco-modal-blur' : '')} id="appRoot">
                            <Layout />
                            <div className="container__wrap">
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard/>}/>
                                    <Route path="/builder/strengths" element={<BuilderStrengths/>}/>
                                    <Route path="/builder/needs" element={<BuilderNeeds/>}/>
                                    <Route path="/builder/prioritize" element={<BuilderPrioritize/>}/>
                                    <Route path="/builder/select" element={<BuilderSelect/>}/>
                                    <Route path="/builder/export" element={<BuilderExport/>}/>
                                    <Route path="/browser/topics" element={<BrowserTopics/>}/>
                                    <Route path="/search" element={<Search/>}/>
                                    <Route path="/plan" element={<AccoCart/>}/>
                                    <Route path="/my-profile" element={<MyAccount userProfile={profile}/>}/>
                                    <Route path="/data-import" element={<DataImport/>} />
                                    <Route path="/" element={<Dashboard/>}/>
                                </Routes>
                            </div>
                        </div>
                        <AccoModal/>
                    </>
                : pageState === 0 ?
                    <Routes>
                        <Route path="/" element={<Login/>} />
                        {/*<Route path="/register" element={<RegisterIndividual/>} />*/}
                        <Route path="/register/complete" element={<RegisterComplete/>} />
                        <Route path="/pwreset" element={<PwReset/>} />
                        <Route path="/pwreset-form/:token/:email" element={<PwResetForm/>} />
                        <Route path="/:any" element={<GoToLogin/>} />
                        <Route path="/:any/:any" element={<GoToLogin/>} />
                        <Route path="/:any/:any/:any" element={<GoToLogin/>} />
                        {/*<ScrollToTop/>*/}
                    </Routes>
                : <div>Loading...</div>
                }
            </main>
            <Spinner />
        </MainWrapper>
    );
}


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile,
    modal: state.modal
});

export default connect(mapStateToProps, { loadStrengths, loadUser, keepAlive, setLoginData, removeAlert, setSpinner, loadAllNeeds, logout })(Router);


