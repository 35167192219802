import React, { useState } from 'react';

const BrowserNav = (props: any) => {

    const { category, topicClick } = props;

    const initObj:any = {};
    const [isOpen, setIsOpen] = useState(false);
    const [activeTopic, setActiveTopic] = useState(initObj);


    const toggle = () => {
        setIsOpen(!isOpen);
        return false;
    };
    const clickTopic = ( t:string ) => {
        setActiveTopic( t );
        topicClick(t);
    };

    return (
        <div>
            <a onClick={toggle} className="browserNav-category">{ category.Name }</a>
            <div className={'browserNav-block' + (isOpen ? ' open' : '')} >
                { category.Needs.map( (t:any) =>
                    <div className={"browserNav-subtopic" + (activeTopic.Name===t.Name ? ' active' : '')}
                         key={t.Id}
                         onClick={()=>clickTopic(t)}>{ t.Name }</div>
                )}
            </div>
        </div>
    );
}

export default BrowserNav;
