import React, { useState } from 'react';

import { goals, grades, testGoals } from './rawData';
import { needs } from './needs';
import axios from 'axios';
import { iiepApiUrl } from '../services/helpers';
import { wait } from '../utils/helpers';

const DataImport = ( props:any ) => {
    const { } = props;

    const initArr:any = [];
    const [currentRow, setCurrentRow] = useState( 0 );
    const [currentNeed, setCurrentNeed] = useState( 0 );
    const [issues, setIssues] = useState( initArr );
    const [invalidInserts, setInvalidInserts] = useState( initArr );
    const totalRecords = goals.length;
    const gradesArray = [ "PreK", "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"];

    const needsSet:any = {};
    const needKeys:any = ["Parent Need", "Need", "Set", "Subset"];

    /*
    const getParentNeed = ( needKey:string, needData:any ) => {
        let parentKey:string = '';
        switch (needKey) {
            case 'Need': parentKey = 'Parent Need|'+needData['Parent Need']; break;
            case 'Set': parentKey = 'Need|'+needData['Need']; break;
            case 'Subset': parentKey = 'Set|'+needData['Set']; break;
        }
        return needsSet[parentKey] ? needsSet[parentKey] : {};
    }

    const postNeed = async ( data:any ) => {
        await wait(10);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ouC8NHT1I0a+0U4jYiJ+WQ==';
            let valid = await axios.put('/v2/objects/validate/Needs', data);
            if (valid.data.isValid) {
                let res = await axios.post('/v2/objects/data/Needs', data);
                return res.data;
            }
        } catch(err) {
            console.log('error', err);
        }
    }

    const importNeeds = async () => {
        let i = 0;
        for (const n of goals) {
            for (const needKey of needKeys) {
                const thisKey = needKey+'|'+n[needKey];
                if (!needsSet.hasOwnProperty(thisKey) && n[needKey] !== '') {
                    console.log('processing ' + thisKey);
                    const data = {
                        Key: thisKey,
                        Name: n[needKey],
                        Description: '',
                        ParentNeed: needKey==='Parent Need' ? {} : getParentNeed( needKey, n )
                    }
                    const newData = await postNeed( data );
                    needsSet[thisKey] = newData;           // ADD KEY TO LIST OF THOSE WE PROCESSED
                } else {

                }
            };
            i++;
            setCurrentNeed( i );
        };
    }
    */

    const fetchData = async (data:any) => {
        // VALIDATE THE DATA
        axios.defaults.headers.common['Authorization'] = 'Bearer ouC8NHT1I0a+0U4jYiJ+WQ==';
        try {
            //await wait(1);
            const valid = await axios.post('https://api.bimcatalyst.dev/v2/objects/validate/Goals/', data);
            if (valid.data.isValid) {
                await axios.post('https://api.bimcatalyst.dev/v2/objects/data/Goals/', data);
            } else {
                setInvalidInserts( (currInvalids:any) => {
                    currInvalids.push(data);
                    return currInvalids;
                });
            }
        } catch(err) {
            console.log('error', err);
        }
    };

    const initImport = async () => {
        const results = [];

        for (const g of goals) {
            setCurrentRow( (curVal:number) => curVal + 1 );

            // BUILD THE DATA
            let data:any = {
                Description: g.Description,
                Grades: [],
                Key: g.Key,
                Links: [],
                ParentNeed: null,
                Prerequisites: [],
                Title: g.Title,
            };

            // ADD GRADES TO THE DATA
            gradesArray.map( (theGrade:string) => {
               if (g[theGrade] !== "") {
                   const gradesToAdd = grades.filter( (gr:any) => gr.Key === theGrade );
                   data.Grades = [ ...data.Grades, ...gradesToAdd ];
               }
            });

            // ADD LINKS TO THE DATA
            for (let i=1; i <= 3; i++) {
                if (g['LinkText'+i] !== '' && g['LinkURL'+i] !== '') {
                    data.Links.push({ Title: g['LinkText'+i], Url: g['LinkURL'+i], LinkType: g['LinkType'+i] });
                }
            }

            // ADD THE PARENTNEED TO THE DATA
            let needKey = '';
            needKeys.map( (nk:any) => {
                if (g[nk] !== '') { needKey = nk+'|'+g[nk]; }
            });
            const theNeed = needs.filter( (need:any) => need.Key === needKey );
            if (theNeed.length) {
                data.ParentNeed = { ...theNeed[0] };
            } else {
                data.ParentNeed = {};
            }

            try {
                const res = await fetchData( data );
                results.push( res );
            } catch (err) {
                console.log('data error', err);
            }


            // LOG ANY ISSUES WITH THE DATA
            // if ( !data.Grades.length || !data.ParentNeed || !data.Description.length || !data.Key.length || !data.Title.length ) {
            //     const newIssue = {
            //         noKey: !data.Key.length,
            //         noTitle: !data.Title.length,
            //         noDescription: !data.Description.length,
            //         noGrades: !data.Grades.length,
            //         noNeed: !data.ParentNeed,
            //         goal: g
            //     };
            //     setIssues( (currIssues:any) => {
            //         currIssues.push(newIssue);
            //         return currIssues;
            //     });
            // }


        };
    };


    return (
        <div style={{margin:'30px'}}>
            <h2>Goals Import</h2>
            <p>&nbsp;</p>

            <button className={'btn btn-success'} onClick={()=>initImport()}>Import { totalRecords } Goals</button>

            <p>&nbsp;</p>
            <p>Importing { currentRow } of { totalRecords }</p>
            <div style={{width:'100%',height:'10px',backgroundColor:'#CCCCCC'}}>
                <div style={{width:(currentRow/totalRecords*100)+'%',height:'10px',backgroundColor:'#76ce6a'}}>

                </div>
            </div>

            {/*<br/><br/><br/><hr/><br/><br/><br/>*/}

            {/*<h2>Needs Import</h2>*/}
            {/*<p>&nbsp;</p>*/}

            {/*<button className={'btn btn-success'} onClick={()=>importNeeds()}>Import { totalRecords } Needs</button>*/}

            {/*<p>&nbsp;</p>*/}
            {/*<p>Importing { currentNeed } of { totalRecords }</p>*/}
            {/*<div style={{width:'100%',height:'10px',backgroundColor:'#CCCCCC'}}>*/}
            {/*    <div style={{width:(currentNeed/totalRecords*100)+'%',height:'10px',backgroundColor:'#76ce6a'}}>*/}

            {/*    </div>*/}
            {/*</div>*/}

            {/*<p>Issues Found: { issues.length }</p>*/}

            {/*{ issues.map( (issue:any, i:number) => {*/}
            {/*    return (*/}
            {/*        <div style={{marginTop:'20px'}}>{ i } - { issue.goal.Title }</div>*/}
            {/*    )*/}
            {/*})}*/}
        </div>
    )
}

export default DataImport;

