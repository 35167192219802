import React, { useState } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../redux/modal/modalActions';
import { addToCart, removeFromCart } from '../redux/cart/cartActions';

const SelectTable = (props: any) => {

    const { data, emptyMessage, cart, showCartActions, addToCart, removeFromCart, openModal, category } = props;
    let { catLimit } = props;

    if (catLimit === undefined) {
        catLimit = 10000;
    }

    const [showAll, setShowAll] = useState(false);

    const openTheModal = (title: string, data: any) => {
        openModal(title, 'large', data);
        return false;
    };

    const isInCart = (amId: string) => {
        let inCart = false;
        cart.items.map((item: any) => {
            if (item.Key === amId) inCart = true;
        });
        return inCart;
    }

    return (
        <>
            {data.length > 0 ?
                <>
                    <table className="select-table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>IEP Goals</th>
                            {showCartActions && <th>&nbsp;</th>}
                        </tr>
                        </thead>
                        <tbody>{
                            data.map((d: any, i: number) =>
                                <>
                                    <tr key={'row-' + d.Key}
                                        className={category + (i < catLimit ? ' always' : (!showAll ? ' extra-hidden' : ' extra-shown'))}>
                                        <td>{d.Key}</td>
                                        <td style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                                            <a onClick={() => openTheModal(d.Title, d)}>
                                                <b>{d.Title}</b>
                                                <br/>
                                                <span style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{__html: d.Description }}></span>
                                            </a>
                                        </td>
                                        {showCartActions ?
                                            isInCart(d.Key) ?
                                                <td>
                                                    <div className="cart-remove" onClick={() => removeFromCart(d)}>
                                                        <i className={'far fa-times-circle fa-fw'}></i><span>REMOVE</span>
                                                    </div>
                                                </td>
                                                :
                                                <td>
                                                    <div className="cart-add" onClick={() => addToCart(d)}>
                                                        <i className={'fad fa-files-medical fa-fw ' + (d.selected ? 'on' : '')}></i><span>ADD</span>
                                                    </div>
                                                </td>
                                            :
                                            <></>
                                        }
                                    </tr>
                                </>
                            )
                        }
                        </tbody>
                    </table>
                    {data.length > catLimit &&
                      <p>&nbsp;<br/>
                        <button className={"btn btn-sm " + (showAll ? 'btn-primary' : 'btn-success')}
                                onClick={() => setShowAll(!showAll)}>
                          &nbsp;&nbsp;&nbsp;{showAll ? 'Show ' + (data.length - catLimit) + ' Fewer Matches' : 'Show ' + (data.length - catLimit) + ' More Matches'}&nbsp;&nbsp;&nbsp;
                        </button>
                      </p>
                    }
                </>
                :
                <></>
            }
        </>
    );
};


const mapStateToProps = (state: any) => ({
    cart: state.cart
});

export default connect(mapStateToProps, { openModal, addToCart, removeFromCart })(SelectTable);
