import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import { addNeed, removeNeed } from '../../redux/builder/builderActions';
import { connect } from 'react-redux';
import { openModal } from '../../redux/modal/modalActions';
import {BuilderNeed} from "../../redux/builder/builderModels";

const BuilderNeeds = (props: any) => {

    const { data, builder, addNeed, removeNeed, openModal } = props;

    console.log('data.needs', data.needs);

    const defaultCustomNeeds = [
        { Name: '', Id: 'cn000' },
        { Name: '', Id: 'cn001' },
        { Name: '', Id: 'cn002' },
        { Name: '', Id: 'cn003' },
        { Name: '', Id: 'cn004' },
        { Name: '', Id: 'cn005' }
    ];

    if (builder && builder.needs) {
        builder.needs.forEach((cn:any)=>{
            if (cn.Id.includes('cn00')) {
                let thisId = cn.Id.substr(4,1);
                defaultCustomNeeds[thisId].Name = cn.Name;
            }
        });
    }

    const [customNeeds, setCustomNeeds] = useState( defaultCustomNeeds );

    const toggleNeed = ( need:any, category:string, id:string ) => {
        console.log('toggleNeed', need, category);
        need.Category = category;
        need.id = id;
        need.priority = 1;

        if (isInNeeds(need)) {
            removeNeed(need);
        } else {
            addNeed(need);
        }
    }

    const isInNeeds = ( need:any ) => {
        let isChecked = false;
        builder.needs.map( (n:any) => {
            //console.log( n.id, need.__i.guid );
            if ( n.Id === need.Id ) {
                isChecked = true;
            }
        });
        return isChecked;
    }

    const setCustomNeed = ( i:number, value:string ) => {
        let cn = [ ...customNeeds ];
        cn[i].Name = value;
        setCustomNeeds( cn );
    }

    const submitCustomNeeds = () => {
        customNeeds.map( (cn:any, i:number) => {
            removeNeed(cn);
            if (cn.Name.length) {
                addNeed(cn);
            }
        });
    }

    const builderNeeds: BuilderNeed[] = data?.needs?.length ? data.needs : [];

    return (
        <>
            <PageTitle title='IEP Goals Builder' browse={true} search={true} />

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={2} />
                                <img src={kids2} alt="students" className="" />
                            </div>
                            <div className='acco-builder-body'>
                                <h2 className='ltblue'>Identify your student's needs:</h2>
                                <p>When you think about your student, identify the needs that impact his/her learning. These are the needs we will be writing IEP goals for.</p>

                                { builderNeeds.map(need =>
                                    <div className='acco-select-block' key={need.Id}>
                                        <h3 className='acco-select-block-title ltblue'>{ need.Name }</h3>
                                        <div className='acco-select-block-flex'>
                                            { need.Needs.map( (childNeed:any) =>
                                                <div key={childNeed.Id}>
                                                    <input type='checkbox' checked={isInNeeds(childNeed)} onChange={()=>toggleNeed(childNeed, need.Name, childNeed.Id)} />
                                                    <span className="traitClick" onClick={()=>toggleNeed(childNeed, need.Name, childNeed.Id)}>{ childNeed.Name }</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className='acco-select-block'>
                                    <h3 className='acco-select-block-title ltblue'>Add Your Own Needs</h3>
                                    <div className='acco-select-block-flex'>
                                        { customNeeds.map( (cn:any, i:number ) =>
                                            <div key={cn.Id}>
                                                <label>
                                                    <input type='checkbox' checked={cn.Name.length > 0}/>
                                                    <span>
                                                    <input type='text'
                                                           className="form-control"
                                                           value={cn.Name}
                                                           onChange={(e:any)=>setCustomNeed(i, e.target.value)}
                                                           onBlur={(e:any)=>submitCustomNeeds()} />
                                                </span>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='acco-builder-flowbtn'>
                            <Link to='/builder/select' className={'btn btn-pill btn-lg btn-primary' + (builder.needs.length === 0 ? ' disabled' : '')}>
                                Next Step <i className="far fa-angle-double-right fa-lg"></i>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder,
    data: state.data,
});

export default connect( mapStateToProps, { addNeed, removeNeed, openModal })(BuilderNeeds);

