import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { connect } from 'react-redux';
import { searchAccomods } from '../../redux/search/searchActions';
import SelectTable from '../../components/SelectTable';
import gears from '../../shared/img/gears.gif';
import {setGrades} from "../../redux/data/dataActions";

const Search = (props: any) => {

    const { search, searchAccomods, grades, setGrades } = props;
    const [ searchTerm, setSearchTerm ] = useState( '' );

    //const hasResults = search && !search.isLoading;

    const execSearch = (query: any) => {
        setSearchTerm( query.target.value );

    }

    const clearSearch = () => {
        setSearchTerm( '' );
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if (searchTerm.length >= 3) {
                searchAccomods(searchTerm, grades);
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, searchAccomods, grades])

    return (
        <>
            <PageTitle title='Search Goals' showGrades={true}
                       subtitle={'Looking for a specific IEP goal? Use our search engine to find IEP goals by keyword.'}
                       builder={true} browse={true} search={false}/>
            <Container>
                <Row>
                    {/*<Col>*/}
                    {/*    <div className="grade-select-div-search">*/}
                    {/*        <div className="grade-select">Filter Goals by Grade</div>*/}
                    {/*        <ul>{grades.map((grade: any) =>*/}
                    {/*            <li key={'g' + grade.title}><label>*/}
                    {/*                <input type="checkbox" name={grade.title} value={1} checked={grade.selected}*/}
                    {/*                       onChange={(e: any) => setGrades(e.target.name, grades)}*/}
                    {/*                />*/}
                    {/*                {grade.title}*/}
                    {/*            </label></li>*/}
                    {/*        )}*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col xs={12} md={12}>
                        <div className="search-bar-div">
                            <input className="search-bar"
                                   type='text'
                                   placeholder="Enter search term"
                                   name="searchbox"
                                   value={searchTerm}
                                   onChange={(val: any) => execSearch(val)}
                            />
                            <i className="fas fa-search fa-fw fa-flip-horizontally"></i>
                        </div>
                        <br/>
                        {(search.isLoading === null || searchTerm.length === 0) ?
                            <div></div>
                            :
                            search.isLoading === true ?
                                <div className="gears"><img src={gears} alt="loading gears" className=""/></div>
                                :
                                search.searchResults.length ?
                                    <div>
                                        <p>
                                        <span className="clearSearch" onClick={() => clearSearch()}><i
                                            className='fas fa-times-circle'></i> Clear Search</span>
                                            <b>{search.searchResults.length} Matching Goals</b>
                                        </p>
                                        <p>&nbsp;</p>
                                        <SelectTable data={search.searchResults} showCartActions={true}/>
                                    </div>
                                    :
                                    <div>No results.</div>
                        }
                        <br/><br/>
                        <Link to='/dashboard' className='btn btn-pill btn-primary'>
                            <i className="far fa-angle-double-left fa-lg"></i> &nbsp;Back to Dashboard</Link>
                    </Col>
                </Row>
            </Container>
</>
)
    ;
};

const mapStateToProps = (state: any) => ({
    search: state.search,
    grades: state.data.grades
});

export default connect(mapStateToProps, {searchAccomods, setGrades})(Search);

