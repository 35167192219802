import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { getNeedIdsForSearch, loadGoalsByParentNeed } from '../redux/data/dataActions';
import loadingGif from '../shared/img/loading.gif';
import { wait } from '../utils/helpers';
import {addToCart, removeFromCart} from "../redux/cart/cartActions";

const _ = require("lodash");

const GoalSelectTable = ( props:any ) => {
    const {topNeed, needs, loadGoalsByParentNeed, grades, addToCart, removeFromCart, cart, isSpaced} = props;

    const init: any = [];
    const initObj: any = {};
    const [setFilter, setSetFilter] = useState(topNeed.Needs);
    const [subsetFilter, setSubsetFilter] = useState(init);

    const [activeSet, setActiveSet] = useState('');
    const [activeSubSet, setActiveSubSet] = useState('');
    const [textFilter, setTextFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const [pager, setPager] = useState({index: 0, count: 0, size: 5, page: 0});
    const [goals, setGoals] = useState(init);

    const [loading, setLoading] = useState(false);

    const onChangePager = (field: string, newVal: number) => {
        let tmpPager: any = {...pager};
        tmpPager[field] = newVal;
        tmpPager.index = 0;
        setPager(tmpPager);
    }

    const onSetFilter = (selectedId: string) => {
        //console.log('onsetfilter', selectedId);
        if (selectedId && selectedId.length) {
            const newFilter = setFilter.filter((need: any) => need.Id === selectedId);
            let tmpPager = {...pager};
            tmpPager.index = 0;
            setSubsetFilter(newFilter[0].Needs);
            setActiveSet(selectedId);
            setActiveSubSet('');
            setPager(tmpPager);
        } else {
            let tmpPager = {...pager};
            tmpPager.index = 0;
            setActiveSet('');
            setActiveSubSet('');
            setSubsetFilter([]);
            setPager(tmpPager);
        }
    }

    const isInCart = ( goal:any ) => {
        let isChecked = false;
        cart.map( (g:any) => {
            //console.log( n.id, need.__i.guid );
            if ( goal.__i.guid === g.__i.guid ) {
                isChecked = true;
            }
        });
        return isChecked;
    }

    const toggleGoal = (goal:any) => {
        console.log('goal', goal);
        if (isInCart(goal)) {
            removeFromCart( goal );
        } else {
            addToCart(goal);
        }
    }

    const onSubsetFilter = (selectedId: string) => {
        //console.log('onsubsetfilter', selectedId)
        setActiveSubSet(selectedId);
    }

    const setPage = (dir: string) => {
        let tmpPager = {...pager};
        if (dir === 'prev') {
            tmpPager.index -= tmpPager.size;
        } else {
            tmpPager.index += tmpPager.size;
        }
        setPager(tmpPager);
    }

    // Text delay
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if (textFilter.length >= 3 || textFilter.length === 0) {
                setSearchTerm(textFilter);
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [textFilter])

    // INITIALIZE TABLE
    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = [topNeed.ParentId, topNeed.id, activeSet, activeSubSet];
            const ids = getNeedIdsForSearch(data, needs);
            const res: any = await loadGoalsByParentNeed(ids, searchTerm, pager?.size, pager?.index, grades);
            await wait(100);
            setPager(res.__a);
            setGoals(res.r);
            setSetFilter( topNeed.Needs );
            setSubsetFilter( init );
            setLoading(false);
        })();
    }, [topNeed.ParentId, topNeed.Id, activeSet, activeSubSet, searchTerm, pager.size, pager.index, grades])

    return (
        <div className={"goal-select-table" + (isSpaced ? ' spaced' : '')}>
            <div className="head">
                <div className="title-categories">
                    {topNeed.Category}
                    &nbsp;&nbsp;<i className="fas fa-chevron-double-right"></i>&nbsp;&nbsp;
                    {topNeed.Name}
                </div>
            </div>
            <div className="selects">
                <div>
                    <select className="form-select" onChange={(e: any) => onSetFilter(e.target.value)}
                            disabled={!setFilter[0]}>
                        <option value={''}>-- Filter ---</option>
                        {setFilter.map((n: any) =>
                            <option value={n.Id} key={n.Id}>{n.Name}</option>
                        )}
                    </select>
                </div>
                <div>
                    <select className="form-select" onChange={(e: any) => onSubsetFilter(e.target.value)}
                            disabled={!subsetFilter[0]}>
                        <option value=''>-- Filter ---</option>
                        {subsetFilter.map((n: any) =>
                            <option value={n.Id} key={n.Id}>{n.Name}</option>
                        )}
                    </select>
                </div>
                <div className="last">
                    <input type="text" className="form-control last" value={textFilter}
                           onChange={(e: any) => setTextFilter(e.target.value)}
                           placeholder={'Text Filter'}/>
                </div>
            </div>
            <div className="head">
                <div className="title-results">
                    <div>
                        <i className={"fas fa-triangle fa-rotate-270" + (pager.index === 0 ? ' disabled' : '')}
                           onClick={() => pager.index > 0 ? setPage('prev') : null}></i>
                    </div>
                    {pager.count === 0 ?
                        <div className="result-count">0 Results</div>
                        :
                        <div
                            className="result-count">Viewing {pager.index + 1} to {pager.index + pager.size > pager.count ? pager.count : pager.index + pager.size} of {pager.count} Results</div>
                    }
                    <div>
                        <i className={"fas fa-triangle fa-rotate-90" + (pager.index + pager.size > pager.count ? ' disabled' : '')}
                           onClick={() => pager.index + pager.size <= pager.count ? setPage('next') : null}></i>
                    </div>
                </div>
                <div className="title-results">
                    <div className="result-size">
                        <select className="form-select" value={pager.size}
                                onChange={(e: any) => onChangePager('size', e.target.value)}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                </div>
            </div>
            {loading ?
                <div className="goal-table loading">
                    <img src={loadingGif} style={{width: '70px', height: '70px'}}/>
                </div>
                :
                <div className="goal-table">
                    <table className="table table-striped table-hover">
                        <tbody>
                        {goals.map((g: any) =>
                            <tr key={'goal' + g.__i.guid} onClick={()=>toggleGoal(g)}>
                                <td>{ isInCart(g) ?
                                    <i className={"fas fa-check-circle"}></i>
                                        :
                                    <i className={"far fa-circle"}></i>
                                }
                                </td>
                                <td>
                                    <div>
                                        <b>{g.Title}</b>
                                        <div style={{float: 'right'}}>
                                            {g.Grades.map((g: any, i: number) =>
                                                <span key={g.__i.guid + 'grade' + i}>
                                                    {i > 0 ? ', ' : ''}{g.Grade}
                                                </span>)
                                            }
                                        </div>
                                    </div>
                                    <div>{g.Description}</div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}


const mapStateToProps = ( state:any ) => ({
    needs: state.data.needs,
    grades: state.data.grades,
    cart: state.cart.items
});

export default connect(mapStateToProps, { loadGoalsByParentNeed, addToCart, removeFromCart })(GoalSelectTable);

