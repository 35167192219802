import React from 'react';
import { connect } from 'react-redux';

const Spinner = (props: any) => {
    const { spinner } = props;

    return ( spinner.ctr > 0 ?
        <div className={'loader-full'}>
            <div className={'spin-bg'}>
                <div className={'spin-spinner'}>
                    {/*<i className="fad fa-hurricane fa-spin"></i>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
                        <g className="spinner_OSmW">
                            <rect x="33" y="3" width="6" height="15" opacity=".14"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(30 36 36)" opacity=".29"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(60 36 36)" opacity=".43"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(90 36 36)" opacity=".57"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(120 36 36)" opacity=".71"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(150 36 36)" opacity=".86"/>
                            <rect x="33" y="3" width="6" height="15" transform="rotate(180 36 36)"/>
                        </g>
                    </svg>
                </div>
                <div className={'spin-label'}>{ spinner.label }</div>
            </div>
        </div>
            :
            <></>
    );
};



const mapStateToProps = (state: any) => ({
    spinner: state.spinner
});

export default connect(mapStateToProps, {})(Spinner);


