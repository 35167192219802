import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    SET_DISTRICT,
    USER_LOADED,
    LOAD_USER,
    SET_TOKENDATA,
    SET_CURRENT_USER,
    SET_USERSETTINGS
} from '../types';

const initialState = {
    token: {
        '.expires': null,
        '.issued': null,
        access_token: "",
        'as:client_id': "",
        isCustomer: null,
        organization: "",
        token_type: "",
        userId: "",
        userName: "",
    },
    user: {
        id: null,
        emailAddress: null,
    },
    userSettings: {}
};

export default function( state = initialState, action:any ) {
    const { type, payload } = action;

    switch(type) {
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
        case SET_TOKENDATA:
            return {
                ...state,
                token: payload
            };

        case LOAD_USER:
        case SET_CURRENT_USER:
            return {
                ...state,
                user: payload
            };

        case SET_USERSETTINGS:
            return {
                ...state,
                userSettings: payload
            }

        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            return initialState;

        default: return state;

    }
}
