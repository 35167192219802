import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../shared/img/logo.png';
import { apiURL, getHostName } from '../../services/helpers';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';

const PwReset = (props: any) => {
    const {} = props;

    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(-1);
    const [payError, setPayError] = useState('');
    const [formData, setFormData] = useState({
        uid: '',
        org: 'intentiep'
    });

    const sendPwdResetEmail = async ( email:string, token:string ) => {
        const resetUrl = getHostName() + '/pwreset-form/' + token + '/' + formData.uid + '/';
        const data = {
            api_key: 'api-572925FCBC364912AD33E54297EA589A',
            to: [email],
            sender: "The Intentional IEP <noreply@theintentionaliep.com>",
            subject: 'Password Reset - The Intentional IEP',

            text_body:
                'Someone requested a link to reset the password associated with an Intentional IEP account using this email address.\n\n' +
                'If you did not make this request, you can ignore it.\n\n' +
                'If you continue to receive these requests, please contact The Intentional IEP support.\n\n' +
                'To reset your password, please click the link below:\n' +
                resetUrl+'\n\n\n' +
                'Kind regards,\n' +
                'The Intentional IEP Team\n\n\n',

            html_body:
                '<h2>Password Reset - The Intentional IEP</h2>' +
                '<p>Someone requested a link to reset the password associated with an Intentional IEP account using this email address.</p>' +
                '<p>If you did not make this request, you can ignore it.</p>' +
                '<p>If you continue to receive these requests, please contact The Intentional IEP support.</p>' +
                '<p>&nbsp;</p>' +
                '<p>To reset your password, please click the link below:</p>' +
                '<p>&nbsp;<br/><a href="'+resetUrl+'" style="padding:8px 20px;background-color:#3C85C2;color:#FFFFFF;border-radius:6px;text-decoration:none">Reset Password</a></p>' +
                '<p>&nbsp;</p>' +
                '<p>Kind regards,' +
                '<br/>The Intentional IEP Team</p>',

            custom_headers: [
                {
                    header: 'Reply-To',
                    value: "The Intentional IEP <noreply@theintentionaliep.com>",
                }
            ]
        };

        try {
            await axios.post('https://api.smtp2go.com/v3/email/send', data);
            return true;
        } catch(err) {
            return false;
        }
    }

    const submitForm = async () => {
        setSubmitting(true);
        try {
            const body = new URLSearchParams(formData).toString();
            const res: any = await axios.post('/api/v2/sys/Account/pwd/forgot', body);
            if (res.data.token) {
                await sendPwdResetEmail( formData.uid, res.data.token );
                setSuccess(1);
            }
            setSubmitting(false);

        } catch (err) {
            setSubmitting(false);
            setPayError('There was an error requesting your password reset key.');
        }
    }

    return (
        <div className="cmt-login auth-form">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Accomods Logo" className="logo"/>
                            </div>
                            {success !== 1 ?
                                <div className='pf-login-panel'>
                                    <h1>Reset Your Password</h1>
                                    <p>Can't sign in to your account? Use this form to reset your password.</p>
                                    <p>Enter your email address, and if it exists in our system you will receive an
                                        email with a special link where you can then change your password.<br/>&nbsp;
                                    </p>

                                    <div className={`form-group`}>
                                        <label>Email Address</label>
                                        <div className="auth-form-group">
                                            <div className="ico-left"><i className="fas fa-envelope"></i></div>
                                            <input
                                                name="userOrEmail"
                                                value={formData.uid}
                                                type="text"
                                                onChange={(e: any) => setFormData({
                                                    ...formData,
                                                    uid: e.target.value
                                                })}
                                                className="form-control input-ico-left"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {payError !== '' &&
                                      <div className="alert alert-danger"
                                           style={{ fontSize: '16px', marginTop: '24px' }}>
                                        <b>{payError}</b>
                                      </div>
                                    }

                                    <div>
                                        <div style={{ marginTop: '26px',float:'right',fontStyle:'italic'}}>
                                            <Link to={'/'}>Back to Login</Link>
                                        </div>
                                        <button className="btn btn-success"
                                                style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                                                onClick={() => submitForm()}
                                                disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                                    </div>

                                    <br/><br/>
                                </div>
                                :
                                <div className='pf-login-panel'>
                                    <div className="alert alert-success">
                                        <b style={{ fontSize: '20px' }}>Email Sent!</b>
                                    </div>
                                    <br/>
                                    <p style={{ fontSize: '16px' }}>Please check your inbox for an email from The Intentional IEP.
                                        This email will contain a link that will redirect you to a special form allowing
                                        you to reset your password.</p>
                                    <p style={{ fontSize: '16px' }}>Having problems? Please contact The Intentional IEP support at <a
                                            href="mailto:support@theintentionaliep.com">support@theintentionaliep.com</a>
                                    </p>
                                    <br/><br/>
                                    <Link to={"/"} className="btn btn-primary"
                                          style={{ paddingLeft: '40px', paddingRight: '40px' }}>Back
                                        to Home</Link>
                                </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PwReset);

