import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { closeModal, getTraitDescription } from '../redux/modal/modalActions';
import SelectTable from './SelectTable';
import { addToCart, removeFromCart } from '../redux/cart/cartActions';

const AccoModal = (props: any) => {

    const { closeModal, getTraitDescription, modal, cart, builder, addToCart, removeFromCart  } = props;

    const closeTheModal = () => { closeModal(); };

    const isSmallModal = modal && modal.modalSize && modal.modalSize === 'small';

    if (isSmallModal && modal.trait.Name === '') {
        getTraitDescription( modal.accomodId );
    }

    const walk_the_DOM = function walk(node:any, func:any) {
        func(node);
        node = node.firstChild;
        while (node) {
            walk(node, func);
            node = node.nextSibling;
        }
    };

    const stripAttributes = ( html:string ) => {
        const wrapper= document.createElement('div');
        wrapper.innerHTML = html;
        walk_the_DOM(wrapper, function(element:any) {
            if (element.removeAttribute) {
                element.removeAttribute('id');
                element.removeAttribute('style');
                element.removeAttribute('class');
            }
            if (element.nodeName === 'A') {
                element.setAttribute( 'target', '_blank' );
            }
            element.textContent.replace('&nbsp;',' ');

        });
        const result = wrapper.innerHTML;

        return result === '' ? 'None' : result;
    }

    const isInCart = ( amId:string ) => {
        let inCart = false;
        cart.items.map((item:any) => {
            if (item.Key === amId) inCart = true;
        });
        return inCart;
    }

    return (
        <div className={'acco-modal' + (modal.open ? ' open' : '') + ' ' + modal.modalSize}>
            <div className="acco-modal-wrap">
            <div className="acco-modal-body">
                <div className="acco-modal-scroller">
                <div className="acco-modal-content">
                    { modal.modalSize === 'small' ?
                        <>
                            <h2>{ modal.trait.Name }</h2>
                            <p>{ modal.trait.Description }</p>
                        </>
                        : modal.modalSize === 'large' ?
                        <>
                            <div className="pdf-header"></div>

                            <div className="pdf-body">
                                <h2 className="blue">{modal.accomod.Title}</h2>

                                <h4>Description of Goal:</h4>
                                <div dangerouslySetInnerHTML={{__html: stripAttributes(modal.accomod.Description) }} />

                                <h4>Skill Grade Level:</h4>
                                <div>{ modal?.accomod?.Grades?.length &&
                                    modal.accomod.Grades.map( (grade:any, i:number ) =>
                                        <span>{i > 0 ? ', ' : ''}{grade.Grade}</span>
                                    )
                                }
                                </div>

                                <h4>IEP Screeners and Data Sheets:</h4>
                                <div><ul>{ modal?.accomod?.Links?.length &&
                                    modal.accomod.Links.map( (link:any, i:number ) =>
                                        <li><a href={link.Url} target="_blank">{ link.Title }</a></li>
                                    )
                                }</ul>
                                </div>

                            </div>
                        </>
                        : modal.modalSize === 'xl' ?
                        <>
                            <div className="pdf-header"></div>
                            <Row>
                                <Col sm={6}>
                                    <div className="written-field">&nbsp;Student Name</div>
                                </Col>
                                <Col sm={2}>&nbsp;</Col>
                                <Col sm={4}>
                                    <div className="written-field">&nbsp;Date</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className='option-list'>
                                    { (builder && builder.needs && builder.needs.length) ? <h4>Student Strengths</h4> : '' }
                                    { (builder && builder.strengths && builder.strengths.length) ?
                                            builder.strengths.map( (s:any) =>
                                                <div><span className="fa-stack fa-2x">
                                                      <i className="far fa-square fa-stack-1x"></i>
                                                      <i className="fas fa-check fa-stack-1x"></i>
                                                    </span> &nbsp;{s.Name}
                                                </div>
                                            ) : <></>
                                    }
                                </Col>
                                <Col sm={6} className='option-list'>
                                    { (builder && builder.needs && builder.needs.length) ? <h4>Student Needs</h4> : '' }
                                    { (builder && builder.needs && builder.needs.length) ?
                                        builder.needs.map( (n:any) =>
                                            <div><span className="fa-stack fa-2x">
                                                      <i className="far fa-square fa-stack-1x"></i>
                                                      <i className="fas fa-check fa-stack-1x"></i>
                                                    </span> &nbsp;{n.Name}
                                            </div>
                                        ) : <></>
                                    }
                                </Col>
                            </Row>
                            { (builder && builder.needs && builder.needs.length) ? <><br/><br/></> : '' }

                            <h4 className="blue" style={{paddingBottom:'12px'}}>IEP Goals Index</h4>
                            <SelectTable data={cart.items}
                                         showCartActions={false}
                                         emptyMessage="You have not placed any goals in your cart yet." />

                            <div className="accomod-list">

                            { (cart && cart.items && cart.items.length) &&
                                cart.items.map((acco:any) =>
                                    <div className="pdf-body">
                                        <h2 className="blue">{acco.Title}</h2>

                                        <h4>Description of Goal:</h4>
                                        <div dangerouslySetInnerHTML={{__html: stripAttributes(acco.Description) }} />

                                        <h4>Skill Grade Level:</h4>
                                        <div>{ acco?.Grades?.length ?
                                            acco.Grades.map( (grade:any, i:number ) =>
                                                <span>{i > 0 ? ', ' : ''}{grade.Grade}</span>
                                            )
                                            : 'Any'
                                        }
                                        </div>

                                        <h4>IEP Screeners and Data Sheets:</h4>
                                        <div><ul>{ acco?.Links?.length &&
                                            acco.Links.map( (link:any, i:number ) =>
                                                <li><a href={link.Url} target="_blank">{ link.Title }</a></li>
                                            )
                                        }</ul>
                                        </div>
                                    </div>
                                )
                            }
                            </div>
                        </>
                        : <></>
                    }
                </div>
                </div>
                <div className="acco-modal-close" onClick={() => closeTheModal()}>
                    <i className="fas fa-circle"></i>
                    <i className="far fa-times-circle"></i>
                </div>
                { modal.modalSize === 'large' ?
                    isInCart( modal.accomod.Key ) ?
                        <div className="acco-modal-save">
                            <button className="btn btn-danger" onClick={() => removeFromCart(modal.accomod)}>Remove from Plan</button>
                        </div>
                        :
                        <div className="acco-modal-save">
                            <button className="btn btn-success" onClick={() => addToCart(modal.accomod)}>Add to Plan</button>
                        </div>
                    : <></>
                }
            </div>
            </div>
        </div>
    );
};




const mapStateToProps = (state: any) => ({
    modal: state.modal,
    cart: state.cart,
    builder: state.builder
});

export default connect(mapStateToProps, { addToCart, removeFromCart, closeModal, getTraitDescription })(AccoModal);

