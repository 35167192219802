import { LOAD_NEEDS_CATS, LOAD_RAW_NEEDS, LOAD_STRENGTH_CATS, SEARCH_GOALS, SET_GRADES } from '../types';
import axios from "axios";
import { getNeedsBranch, organizeNeeds } from './dataHelper';

const _ = require('lodash');

export const setGrades = ( grade:string, grades:any ) => async (dispatch:any) => {
  grades = grades.map( (g:any) => {
    if (g.title === grade) {
      g.selected = !g.selected;
    }
    return g;
  })
  dispatch({ type: SET_GRADES, payload: grades });
}


export const loadStrengths = () => async (dispatch:any) => {
  try {
    const res = await axios.get('/api/v2/objects/data/StrengthCategory?size=10&i=0&sort=asc');
    let strengths:any = [];
    if (res.data.r.length) {
      strengths = res.data.r.map( ( category:any ) => {
        let cat = { Name: category.Name, Strengths: [] };
        cat.Strengths = category.Strengths.Documents.map( (strength:any) => {
          return {
            Name: strength.Title,
            __i: { guid: strength.Id }
          }
        })
        return cat;
      })
    }

    dispatch({
      type: LOAD_STRENGTH_CATS,
      payload: strengths
    });
  } catch(err) {
    console.log('error loading strengths');
  }
}


export const loadAllNeeds = () => async (dispatch: any) => {
  try {
    let res: any = await axios.get('/api/v2/objects/data/Needs?size=5000&t=&sort=asc&i=0&q=');

    if (res.data.r.length) {
      const simplified = res.data.r.map((need: any) => {
        //need.ParentNeed = need.ParentNeed?.__i?.guid;
        need.Id = need.__i.guid;
        need.ParentId = need.ParentNeed?.__i?.guid || null;
        delete need.ParentNeed;
        delete need.__i;
        return need;
      });
      const hierarchy:any = organizeNeeds( simplified );
      dispatch({ type: LOAD_RAW_NEEDS, payload: simplified });
      dispatch({ type: LOAD_NEEDS_CATS, payload: hierarchy });
    }
  } catch (err) {
    console.log('error', err);
  }
}


export const getNeedIdsForSearch = ( needIds:string[], needsHierarchy:any ) => {
  let keys:string[] = [];
  let lastKey = '';

  needIds.map( (needId:any) => {
    if (needId && needId !== '') {
      keys = [needId];
      lastKey = needId;
    } else {
      keys = [ ...getNeedsBranch( keys, lastKey, needsHierarchy ) ];
    }
  });
  return  _.uniq(keys);
}


export const loadGoalsByParentNeed = (needIds: string[], textFilter:string, size:number, i:number, grades:any ) => async (dispatch: any) => {
  try {
    const quotedStrings = needIds.map((str) => `"${str}"`);
    const inClauseValues = quotedStrings.join(', ');
    const inGrades:any = [];

    grades.map( (g:any) => {
      if (g.selected) {
        inGrades.push(`"${g.title}"`);
      };
    });

    let q = '';
    const inClause = '{"Payload.ParentNeed.__i.guid":{$in:['+inClauseValues+']}}';
    const textClause = '{$or:[{"Payload.Title":{$regex:"'+textFilter+'",$options:"i"}},{"Payload.Description":{$regex:"'+textFilter+'",$options:"i"}}]}';
    const gradesClause = '{"Payload.Grades.Grade":{$in:['+inGrades+']}}';
    const sort = 'Title:asc,Description:asc';
    if ( textFilter.length || inGrades.length ) {
      q = '{$and:['+inClause + (textFilter.length ? ','+textClause : '') + (inGrades.length ? ','+gradesClause : '')+']}';
    } else {
      q = inClause;
    }

    const res = await axios.get('/api/v2/objects/data/Goals?size='+size+'&t=&sort='+encodeURIComponent(sort)+'asc&i='+i+'&q='+encodeURIComponent(q));
    return res.data;

  } catch (err) {
    console.log('error loading Needs No Parent');
    return [];
  }
}

