import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNeed, removeNeed } from '../../redux/builder/builderActions';
import { openModal } from '../../redux/modal/modalActions';
import { setGrades } from '../../redux/data/dataActions';


const BuilderNav = (props: any) => {

    const { step, grades, setGrades } = props;

    return (
        <>
        <ul className='acco-builder-navlist'>
            { step > 1 ?
                <li className='on'><Link to={'/builder/strengths'}><i>1</i> Identify Strengths</Link></li>
                :
                <li className={ step===1 ? 'on' : 'off'}><div><i>1</i> Identify Strengths</div></li>
            }
            { step > 2 ?
                <li className='on'><Link to={'/builder/needs'}><i>2</i> Identify Needs</Link></li>
                :
                <li className={ step===2 ? 'on' : 'off'}><div><i>2</i> Identify Needs</div></li>
            }
            {/*{ step > 3 ?*/}
            {/*    <li className='on'><Link to={'/builder/prioritize'}><i>3</i> Prioritize Needs</Link></li>*/}
            {/*    :*/}
            {/*    <li className={ step===3 ? 'on' : 'off'}><div><i>3</i> Prioritize Needs</div></li>*/}
            {/*}*/}
            { step > 4 ?
                <li className='on'><Link to={'/builder/select'}><i>3</i> Select Goals</Link></li>
                :
                <li className={ step===4 ? 'on' : 'off'}><div><i>3</i> Select Goals</div></li>
            }
            { step > 5 ?
                <li className='on'><Link to={'/builder/export'}><i>4</i> Export / Share Plan</Link></li>
                :
                <li className={ step===5 ? 'on' : 'off'}><div><i>4</i> Export / Share Plan</div></li>
            }
        </ul>

            { step === 4 ?
                <div className="grade-select-div">
                <div className="grade-select">Filter Goals by Grade</div>
                    <ul>{ grades.map( (grade:any) =>
                        <li key={'g'+grade.title}><label>
                            <input type="checkbox" name={grade.title} value={1} checked={grade.selected}
                                   onChange={(e:any)=>setGrades(e.target.name, grades)} />
                            { grade.title }
                        </label></li>
                    )}
                    </ul>
                </div>
                :
                <></>
            }
        </>
    );
};


const mapStateToProps = (state: any) => ({
    grades: state.data.grades
});

export default connect( mapStateToProps, { setGrades })(BuilderNav);
