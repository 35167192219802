export const grades = [
    {
        "Grade": "Pre-K",
        "Key": "Pre-K",
        "__i": {
            "guid": "77daba90-6508-46a8-be68-d07dacce3fd8",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:44:55.6140000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "Kindergarden",
        "Key": "K",
        "__i": {
            "guid": "79f27f8c-0c96-44ea-82e5-e4132c081bc3",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:08.1010000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "1st Grade",
        "Key": "1st",
        "__i": {
            "guid": "94986c96-7661-4f8f-845e-51243ff514ad",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:21.9110000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "2nd Grade",
        "Key": "2nd",
        "__i": {
            "guid": "d229ec0f-10c6-43a8-a8cb-c4c768c193b4",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:25.9230000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "3rd Grade",
        "Key": "3rd",
        "__i": {
            "guid": "02e8daef-eb69-4f35-a763-81d240483160",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:29.9110000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "4th Grade",
        "Key": "4th",
        "__i": {
            "guid": "87eb2868-18af-405c-b4c8-9cf76980f1d7",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:34.4120000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "5th Grade",
        "Key": "5th",
        "__i": {
            "guid": "42313987-0739-4d26-afa1-d287a5a21478",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:39.3140000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "6th Grade",
        "Key": "6th",
        "__i": {
            "guid": "ab1adcb9-1d6c-4523-a811-48fc91ce41fb",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:44.6310000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "7th Grade",
        "Key": "7th",
        "__i": {
            "guid": "6d1e81b5-4c13-4a60-a8b2-5e7f76962e96",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:57:50.2410000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "8th Grade",
        "Key": "8th",
        "__i": {
            "guid": "40a60d9c-28d7-4cbb-be07-c68776471a77",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:58:25.6580000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "9th Grade",
        "Key": "9th",
        "__i": {
            "guid": "4e8065a8-5f19-4d8d-9432-4cb5b2f9dee4",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:58:30.1470000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "10th Grade",
        "Key": "10th",
        "__i": {
            "guid": "526370a7-a687-4c46-a00e-2abed961349e",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:58:34.5320000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "11th Grade",
        "Key": "11th",
        "__i": {
            "guid": "2dd68662-008a-4d00-bf74-26a39626d853",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:58:38.2120000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    },
    {
        "Grade": "12th Grade",
        "Key": "12th",
        "__i": {
            "guid": "d7809047-2e07-4765-b23e-076aab693f21",
            "tags": [],
            "acl": 3,
            "model": {
                "name": "Grades",
                "id": "462b118c-062f-4844-8bbf-16976ec2e216",
                "version": null
            },
            "auditing": {
                "createdBy": "6cbd7180-5c27-4cbd-af7d-16d1dd4ada32",
                "createdOn": "2023-03-02T15:58:43.5890000Z",
                "lastUpdatedBy": null,
                "lastUpdatedOn": null
            }
        }
    }
];

export const testGoals = [];

export const goals:any = [];
