import axios from 'axios';
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_CURRENT_USER,
  SET_DISTRICT,
  SET_USERSETTINGS,
  SPINNER_OFF,
  SPINNER_ON,
  USER_LOADED
} from '../types';

import { removeAlert, setAlert } from "../alert/alertActions";
import { setAuthToken } from "../../utils/setAuthToken";
import { iiepApiUrl } from '../../services/helpers';
import Cookies from 'universal-cookie';
import { domainRoot, nextweek } from '../../utils/helpers';
import { wait } from '@testing-library/user-event/dist/utils';

const cookies = new Cookies();


export const loadUser = ( token:string ) => async (dispatch:any) => {
  if (!!token) {
    try {
      const res = await axios.get('/api/v2/sys/Account');
      //dispatch( getUserSettings(res.data) );
      
      const isInactiveUser = res.data.roles.find((role: any) => role.name === 'InactiveUser');
      if(isInactiveUser) {
        logout();
        dispatch(setAlert('inactiveUser', 'You do not have an active IEP Subscription.', [
            'Please subscribe at https://theintentionaliep.thrivecart.com/intentional-iep-subscription/', 
          'If you believe this is an error, please contact support at support@theintentionaliep.com.'
        ], 'danger'));
        return 0;
      }
      else {
        dispatch({type: SET_CURRENT_USER, payload: res.data});
        return 1;
      }
    } catch(err) {
      console.log('error getting currentUser', err);
      dispatch({ type: SET_CURRENT_USER, payload: {} });
      return -1;
    }
  } else {
    dispatch({ type: SET_CURRENT_USER, payload: {} });
    return -1;
  }
}



// LOOKUP SCHOOL CODE
export const schoolDistrictLookup = (districtCode:string) => async (dispatch:any) => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get(iiepApiUrl() + '/school/code/' + districtCode, config);
    //console.log(res);
    if (res.data.success) {
      const data = {
        name: res.data.payload.school_name,
        code: res.data.payload.school_code,
        isActive: res.data.payload.active,
        expires: res.data.payload.expires,
      };
      dispatch({
        type: SET_DISTRICT,
        payload: data,
      });
    } else {
      dispatch(setAlert('codeError', 'Invalid Code Entered.', [], 'danger'));
    }

  } catch (err) {
    console.log('error');
  }
}


export const setStoredLocalData = () => async (dispatch:any) => {
  dispatch({type: USER_LOADED});
}


// TODO: Make sure this has proper error handling...
export const getUserSettings = ( userData:any ) => async (dispatch:any) => {
  try {
    const q = encodeURIComponent("{'Payload.UserId':{$eq:'"+userData.id+"'}}").replace(/'/g, "%27");
    const res:any = await axios.get('/api/v2/objects/data/UserSettings?size=10&t=&sort=asc&i=0&q='+q);

    // if (!res.data.r.length && userData.id) {
    //   //let settings = buildNewUserSettings( userData );
    //   const valid:any = await axios.put('/api/v2/objects/validate/UserSettings/'+userData.id, settings);
    //   if (valid.data.isValid) {
    //     try {
    //       console.log('Creating UserSettings for ' + userData.id );
    //       const resSettings = await axios.post('/api/v2/objects/data/UserSettings/', settings);
    //       dispatch({ type: SET_USERSETTINGS, payload: resSettings.data });
    //     } catch(err) {
    //       console.log('ERROR creating userSettings', err);
    //     }
    //   } else {
    //     console.log('New UserSettings are INVALID')
    //   }
    // } else {
    //   console.log('Existing UserSettings FOUND, Skipped creating');
    //   dispatch({ type: SET_USERSETTINGS, payload: res.data.r[0] });
    // }


  } catch(err) {
    console.log('error getting userSettings');
    dispatch({ type: SET_USERSETTINGS, payload: {} });
  }
}


export const putUserSettings = ( id:string, data:any ) => async (dispatch:any) => {
  // VALIDATE
  try {
    const valid:any = await axios.put('/api/v2/objects/validate/UserSettings/'+id, data);
    if (valid.data.isValid) {
      const res:any = await axios.put('/api/v2/objects/data/UserSettings/'+id, data);
      dispatch({ type: SET_USERSETTINGS, payload: res.data });
      return true;
    } else {
      console.log('valid fail', valid);
      dispatch(setAlert('contactList', 'Operation Failed', ['An error occurred while attempting to save User Settings.'],'danger'));
      return false;
    }

  } catch(err) {
    console.log('valid fail catch', err);
    dispatch(setAlert('contactList', 'Operation Failed', ['An error occurred while attempting to save User Settings.', err],'danger'));
    return false;
  }
}


export const login = ( loginData:any ) => async (dispatch:any) => {

  try {
    dispatch({ type: SPINNER_ON, payload: 'Signing In' });

    const data:any = {
      "grant_type": "password",
      "username": loginData.userOrEmail,
      "password": loginData.password,
      "organization": "intentiep",
      "isCustomer": true
    };

    console.log('login', data);

    const body = new URLSearchParams(data).toString();
    const res = await axios.post('/api/v2/session/login', body );

    if (res.data && res.data.access_token) {
      await setAuthToken( res.data.access_token );
      cookies.set('token', res.data.access_token, { domain: domainRoot(), path: '/', expires: nextweek(), sameSite: 'lax' });
      cookies.set('catalyst', res.data.access_token, { domain: domainRoot(), path: '/', expires: nextweek(), sameSite: 'lax' });
      cookies.set('intentIepUserData', res.data, { domain: domainRoot(), path: '/', expires: nextweek() });

      dispatch(loadUser(res.data.access_token));
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch({ type: SPINNER_OFF });
      dispatch(removeAlert('signin'));

      return true;
    }
    else {
      console.log('bad login')
      dispatch(setAlert('signin', 'Login failed', ['Invalid email or password'],'danger'));
      return false;
    }

  } catch(err) {
    console.log('login fail', err);
    dispatch(setAlert('signin', 'Login failed', ['Invalid email or password'],'danger'));
    dispatch({ type: SPINNER_OFF });
    dispatch({ type: LOGIN_FAIL });

    return false;
  }
};


// Register User
export const registerUser = (regData:any) => async (dispatch:any) => {
  const {fname, lname, email, password, phone, district, districtCode, acctType} = regData;

  try {
    dispatch(removeAlert('regCode'));

    const data = {
      email: email,
      fname: fname,
      lname: lname,
      school: district,
      code: districtCode,
      phone: phone,
      password: password,
      type: acctType
    };

    const res = await axios.post(iiepApiUrl() + '/auth/register', data);

    if (res.data.success) {
      //await sendConfirmationEmail( regData );
      dispatch({type: REGISTER_SUCCESS, payload: res.data.payload});
      //dispatch(loadUser());

    } else {
      dispatch(setAlert('regCode', 'There was a problem creating your account.', [res.data.error], 'danger'));
      dispatch({type: REGISTER_FAIL});
    }

  } catch (err) {
    //const errors = err.response.data.errors;
    dispatch(setAlert('regCode', 'An unknown error occurred while creating your account.', [], 'danger'));
    dispatch({type: REGISTER_FAIL});
  }

};

export const setLoginData = ( loginData:any ) => async (dispatch:any) => {
  await setAuthToken( loginData.access_token );
  dispatch({ type: LOGIN_SUCCESS, payload: loginData });
}

export const keepAlive = () => async (dispatch:any) => {
  try {
    await axios.get('/api/v2/session/keep-alive' );
  } catch(err) {
    console.log('keep alive error');
  }
}


// Logout User / Clear Profile
export const logout = () => async (dispatch: any) => {
  //Local
  // cookies.remove('intentIepUserData', { path: '/'});
  // cookies.remove('token', { path: '/'});
  // cookies.remove('catalyst', { path: '/'});
  //Dev/prod
  cookies.remove('intentIepUserData', { path: '/', domain: domainRoot()});
  cookies.remove('token', { path: '/', domain: domainRoot()});
  cookies.remove('catalyst', { path: '/', domain: domainRoot()});

  dispatch({ type: LOGOUT });
}

// UPDATE PASSWORD
export const setPassword = ( currentPw:string, newPw1:string, newPw2:string) => async (dispatch:any) => {
  dispatch(removeAlert('pwchange'));
  try {
    const data = {
      current: currentPw,
      password: newPw1,
      confirmPassword: newPw2
    }
    const body = new URLSearchParams(data).toString();
    const res = await axios.post('/api/v2/sys/Account/pwd/set', body );
    return true;
  } catch(err) {
    console.log('PW Change failed', err);
    dispatch(setAlert('pwchange', 'Password Change Failed', [],'danger'));
    return false;
  }
}


// SEND WELCOME EMAIL
// const sendConfirmationEmail = async ( values ) => {
//     const requestDate = new Date();
//     const data = {
//         api_key: process.env.REACT_APP_SMTP2GO_API,
//         to: [values.fname + ' ' + values.lname + ' <' + values.email + '>'],
//         sender: 'Accomods <support@accomods.com>',
//         subject: 'Welcome to Accomods!',
//         text_body: 'Welcome to Accomods!',
//         html_body: '<p><b>Welcome to Accomods!</b></p>',
//
//         custom_headers: [
//             {
//                 header: 'Reply-To',
//                 value: 'Accomods <support@accomods.com>'
//             }
//         ]
//     };
//
//     try {
//         const sendEmail = await axios.post('https://api.smtp2go.com/v3/email/send', data);
//     } catch (err) {
//         console.log(err);
//     }
// }
