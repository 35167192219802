import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import logo from '../../shared/img/logo.png';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import {UserData} from "../../models/UserData";
import {Buffer} from 'buffer';

const PwResetForm = ( props:any ) => {
    const { match } = props;

    const initObj:any = {};
    const params:any = useParams();
    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( -1 );
    const [newPassword, setNewPassword] = useState('');
    const [showPw, setShowPw] = useState( false );
    const [showError, setShowError] = useState( false );
    const [errorMsg, setErrorMsg] = useState( initObj );

    const token = params.token;
    const email = params.email;

    const submitForm = async () => {
        setSubmitting(true);
        setShowError( false );
        let tmpData:any = {
            "token": token,
            "uid": email,
            "password": newPassword,
            "confirmPassword": newPassword
        }

        try {
            const body = new URLSearchParams(tmpData).toString();
            const res = await axios.post( '/api/v2/sys/Account/pwd/reset', body );
            
            const userData: UserData = {
                email: email,
                firstName: '',
                lastName: ''
            }
            const paramsToken = Buffer.from(JSON.stringify(userData), 'utf8').toString('base64');
            
            //Clear the registration if they jump straight into pw reset. This will fail if already done so the result is ignored.
            try {
                const completeRes = await axios.post('/iep-api/registration/complete',
                    {
                        token: paramsToken
                    }
                );
            }
            catch(err) {
                
            }
            setSuccess(1);
            setShowError(true);
            setSubmitting(false);
        } catch(err) {
            setErrorMsg( err );
            setShowError(true);
            setSubmitting(false);
        }
    }

    return (
        <div className="cmt-login auth-form">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Accomods Logo" className="logo"/>
                            </div>
            { success !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Reset Your Password</h1>
                    <p>Enter your new password below.<br/>Click the lock icon to show or hide your password. <br/>&nbsp;</p>

                    {showError ?
                        <div className={'alert alert-danger'}>
                            <p><b>An error occurred while attempting to change your password.</b></p>
                            <ul style={{marginLeft:'15px'}}>
                                { errorMsg?.response?.data ? <li>{errorMsg?.response?.data}</li> :<></> }
                                { errorMsg?.message ? <li>{errorMsg?.message}</li> :<></> }
                            </ul>
                        </div>
                        :
                        <></>
                    }

                    <div className={`form-group`}>
                        <label>New Password</label>
                        <div className="auth-form-group">
                            <div className="ico-left"><i className="fas fa-key"></i></div>
                            <input
                                name="password"
                                value={newPassword}
                                type={showPw ? 'text' : 'password'}
                                onChange={(e: any) => setNewPassword(e.target.value)}
                                minLength={6}
                                className="form-control input-ico-left input-ico-right"
                                required
                            />
                            <div className="ico-right"
                                 onClick={()=>setShowPw(!showPw)}>
                                <i className={`fas ` + (showPw ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                            </div>
                        </div>
                    </div>


                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                            onClick={() => submitForm()}
                            disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Password Reset Successfully!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>Please continue to the login screen to sign in with your new password.</p>
                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                        Continue to Login
                    </Link>
                </div>
            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(PwResetForm);

